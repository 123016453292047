const PrimeModal = () => {
  return <>
    <div className="modal fade" id="primeModal" tabIndex="-1" aria-labelledby="primeModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-fullscreen-lg-down">
        <div className="modal-content bg-info bg-gradient">
          <div className="modal-header border-0">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">

            <div className="container px-md-2 px-lg-5">
              <div className="row">
                <div className="col-12 text-center">
                  <i className="fas fa-shield-alt fa-4x text-white" />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <span className="d-block h2 text-uppercase fw-bold text-white mt-3 mb-0">Kraftwagen Trust</span>
                  <span className="d-block h2 fw-light text-white">Zahlung &amp; Lieferung</span>
                </div>
              </div>
            </div>

            <div className="container d-block d-md-none">
              <div className="row">
                <div className="col-12">
                  <div className="text-center mt-5">
                    <i className="fas fa-door-open fa-3x text-secondary" />
                    <span className="d-block h1 text-secondary mt-3 fw-bold">Bezahlung &amp; Lieferung</span>
                  </div>
                  <div className="p-3 mt-3 text-dark bg-white rounded">
                    Sie bezahlen erst nach persönlichem Empfang. Damit Sie auch ein sicheres Bauchgefühl haben bieten wir Ihnen in Zeiten von Corona die 
                    Bezahlung nach der kostenfreien Lieferung an.
                  </div>
                  <div className="small ms-3 mt-3 mt-md-0" style={{fontSize: 10}}>
                    - Lieferung mit Kraftwagen Transporter<br/>
                    – Innerhalb 7 Werktagen<br/>
                    – Zahlungseingang bei Anlieferung<br/>
                    – 50 km kostenfrei (1,00 EUR/km)
                  </div>
                </div>
                <div className="col-12">
                  <div className="text-center mt-5">
                    <i className="fas fa-undo-alt fa-3x text-secondary" />
                    <span className="d-block h1 text-secondary mt-3 fw-bold">30 Tage Rückgaberecht bei Onlinekauf</span>
                  </div>
                  <div className="p-3 mt-3 text-dark bg-white rounded">
                    Um Ihnen mehr Sicherheit und ein gutes Gefühl zu bieten nehmen wir jedes Fahrzeug 30 Tage nach Übergabe zurück.
                  </div>
                  <div className="small ms-3 mt-3 mt-md-0" style={{fontSize: 10}}>
                    - 30 Tage ab Empfangsdatum<br/>
                    – nur bei triftigem Grund<br/>
                    – Mehrkosten entstehen durch gefahrene KM, Mehrhalter und Verschleißpauschale je nach Modell.
                  </div>
                </div>
                <div className="col-12">
                  <div className="text-center mt-5">
                    <i className="fas fa-laptop-house fa-3x text-secondary" />
                    <span className="d-block h1 text-secondary mt-3 fw-bold">Videotermine &amp; Beratung</span>
                  </div>
                  <div className="p-3 mt-3 text-dark bg-white rounded">
                      Um Fahrzeugbesichtigungen auch bequem von zu Hause aus zu ermöglichen, bieten wir Ihnen Videotermine an.
                  </div>
                  <div className="small ms-3 mt-3 mt-md-0" style={{fontSize: 10}}>
                    – nur mit Terminabsprache<br/>
                    – mit professionellem Equipment<br/>
                    – via WhatsApp, Zoom, Google-Meet, Viber oder FaceTime
                  </div>
                </div>
              </div>
            </div>

            <div className="container d-none d-md-block mt-5 px-md-2 px-lg-5">
              <div className="row">
                <div className="col-12 col-md-4 border-end bg-secondary">
                  <div className="mt-3 mt-lg-0">
                    <div className="text-center mx-2 mt-3">
                      <i className="fas fa-door-open fa-3x text-white" />
                      <span className="d-block p text-white mt-3 fw-bold">Bezahlung &amp; Lieferung</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 border-end bg-secondary">
                  <div className="mt-3 mt-lg-0">
                    <div className="text-center mx-2 mt-3">
                      <i className="fas fa-undo-alt fa-3x text-white" />
                      <span className="d-block p text-white mt-3 fw-bold">30 Tage Rückgaberecht bei Onlinekauf</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 border-end bg-secondary">
                  <div className="mt-3 mt-lg-0">
                    <div className="text-center mx-2 mt-3">
                      <i className="fas fa-laptop-house fa-3x text-white" />
                      <span className="d-block p text-white mt-3 fw-bold">Videotermine &amp; Beratung</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 border-end bg-secondary">
                  <div className="mt-3">
                    <div className="p mb-3 p-2 text-white">
                      Sie bezahlen erst nach persönlichem Empfang. Damit Sie auch ein sicheres Bauchgefühl haben bieten wir Ihnen in Zeiten von Corona die 
                      Bezahlung nach der kostenfreien Lieferung an.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 border-end bg-secondary">
                  <div className="mt-3">
                    <div className="p mb-3 p-2 text-white">
                      Um Ihnen mehr Sicherheit und ein gutes Gefühl zu bieten nehmen wir jedes Fahrzeug 30 Tage nach Übergabe zurück.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 border-end bg-secondary">
                  <div className="mt-3">
                    <div className="p mb-3 p-2 text-white">
                      Um Fahrzeugbesichtigungen auch bequem von zu Hause aus zu ermöglichen, bieten wir Ihnen Videotermine an.
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mt-lg-1">
                <div className="col-12 col-md-4">
                  <div className="small mt-3 mt-md-0" style={{fontSize: 10}}>
                    - Lieferung mit Kraftwagen Transporter<br/>
                    – Innerhalb 7 Werktagen<br/>
                    – Zahlungseingang bei Anlieferung<br/>
                    – 50 km kostenfrei (1,00 EUR/km)
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="small mt-3 mt-md-0" style={{fontSize: 10}}>
                    - 30 Tage ab Empfangsdatum<br/>
                    – nur bei triftigem Grund<br/>
                    – Mehrkosten entstehen durch gefahrene KM, Mehrhalter und Verschleißpauschale je nach Modell.
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="small mt-3 mt-md-0" style={{fontSize: 10}}>
                    – nur mit Terminabsprache<br/>
                    – mit professionellem Equipment<br/>
                    – via WhatsApp, Zoom, Google-Meet, Viber oder FaceTime
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default PrimeModal;
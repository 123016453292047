import {useEffect} from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Impressum = () => {
  
  useEffect(() => {
    const pageTitle = 'PS Kraftwagen GmbH | Impressum';

    document.title = pageTitle;

    window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)]);
    window._paq.push(['setDocumentTitle', pageTitle]);
    window._paq.push(['trackPageView']);      
  }, []);

  return <>    
    <Header />
    
    <div className="container-fluid mt-5 py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>PS Kraftwagen GmbH<br />
            R&ouml;merstra&szlig;e 84<br />
            73066 Uhingen</p>

            <p>Handelsregister: HRB 740983<br />
            Registergericht: Ulm</p>

            <p><strong>Vertreten durch:</strong><br />
            Sebastian Martin Merkel</p>

            <h2>Kontakt</h2>
            <p>Telefon: 07161 8085101<br />
            E-Mail: info@pskraftwagen.de</p>

            <h2>Umsatzsteuer-ID</h2>
            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
            DE336165022</p>

            <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
            <p><strong>Name und Sitz des Versicherers:</strong><br />
            W&uuml;rttembergische Versicherung AG<br />
            Gutenbergstra&szlig;e 30<br />
            70176 Stuttgart, Deutschland<br />
            <br />
            Telefon: +49 711 662 - 0<br />
            Telefax: +49 711 662 829400<br />
            E-Mail-Sammelpostfach: info@wuerttembergische.de</p>
            <h2>EU-Streitschlichtung</h2>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
}

export default Impressum;

import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {

  return <>
    <div className="container-fluid bg-dark bg-gradient py-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-7 col-md-2 col-xl-2">
            <img src="/images/ps-kraftwagen-logo-weiss.svg" alt="PS Kraftwagen GmbH Logo in weiss" className="img-fluid" />
          </div>
          <div className="col-12 col-md-4 col-xl-4 offset-xl-2">
            <div className="ps-md-4 d-md-none">
              <h5 className="text-white text-center text-md-start fw-bold mt-4 mt-md-0 mb-4"><i className="fas fa-car me-1"></i> PS Kraftwagen GmbH</h5>
              <p className="text-white text-center text-md-start mb-5 mb-lg-0 mb-md-0">
                Römerstraße 84<br/>
                73066 Uhingen<br/>
                <br/>
                Telefon: <a href="tel:+4971618085101" className="text-white">07161 808 51 01</a><br/>
                E-Mail: <a href="mailto:info@pskraftwagen.de?subject=Kontakt über Ihre Webseite" className="text-white">info@pskraftwagen.de</a><br/>
                <br/>
                <span className="d-block fw-bold">Öffnungszeiten</span>
                <span className="d-block">Montag bis Freitag 08:00 - 18:00 Uhr</span>
                <span className="d-block">Samstag 09:00 bis 13:00 Uhr</span>
              </p>
            </div>
            <div className="border-primary border-end ps-md-4 d-none d-md-block">
              <h5 className="text-white text-center text-md-start fw-bold mt-4 mt-md-0 mb-4"><i className="fas fa-car me-1"></i> PS Kraftwagen GmbH</h5>
              <p className="text-white text-center text-md-start mb-5 mb-lg-0 mb-md-0">
                Römerstraße 84<br/>
                73066 Uhingen<br/>
                <br/>
                Telefon: <a href="tel:+4971618085101" className="text-white">07161 808 51 01</a><br/>
                E-Mail: <a href="mailto:info@pskraftwagen.de?subject=Kontakt über Ihre Webseite" className="text-white">info@pskraftwagen.de</a><br/>
                <br/>
                <span className="d-block fw-bold">Öffnungszeiten</span>
                <span className="d-block">Montag bis Freitag 08:00 - 18:00 Uhr</span>
                <span className="d-block">Samstag 09:00 bis 13:00 Uhr</span>
              </p>
            </div>
          </div>
          <div className="col-6 col-md-3 col-xl-2">
            <h5 className="text-white fw-bold mb-2 mb-md-4"><i className="fas fa-info-circle me-1"></i> Informationen</h5>
            <Link to="/team" className="text-white">Kontakt</Link><br/>
            <Link to="/datenschutz" className="text-white">Datenschutz</Link><br/>
            <Link to="/impressum" className="text-white">Impressum</Link>
          </div>
          <div className="col-6 col-md-3 col-xl-2 border-start border-primary pl-4">
            <h5 className="text-white fw-bold mb-2 mb-md-4"><i className="fas fa-users me-1"></i> Social Media</h5>
            <i className="fab fa-fw fa-facebook text-white"></i> <a href="https://www.facebook.com/pskraftwagen" target="_blank" rel="noreferrer" className="text-white">Facebook</a><br/>
            <i className="fab fa-fw fa-instagram text-white"></i> <a href="https://www.instagram.com/pskraftwagen" target="_blank" rel="noreferrer" className="text-white">Instagram</a><br/>
            <i className="fab fa-fw fa-linkedin text-white"></i> <a href="https://www.linkedin.com/in/sebastian-merkel-6610a3200/" target="_blank" rel="noreferrer" className="text-white">LinkedIn</a><br/>
            <i className="fab fa-fw fa-youtube text-white"></i> <a href="https://www.youtube.com/channel/UCovjNsCFqyOoTCb29sRafsQ" target="_blank" rel="noreferrer" className="text-white">YouTube</a><br/>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Footer;

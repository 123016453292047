import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import psKraftwagenLogoWeiss from '../assets/images/ps-kraftwagen-logo-weiss.svg';
import psKraftwagenLogoHorizontal from '../assets/images/ps-kraftwagen-logo-horizontal.svg';

const Header = () => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  useEffect(() => {
    showBurgerMenu ? 
      document.querySelector('body').classList.add('overflow-hidden') : 
      document.querySelector('body').classList.remove('overflow-hidden');
  });

  return <>
    
    {showBurgerMenu ? 
    <div className="container-fluid bg-primary position-fixed w-100 h-100 overflow-auto d-block d-lg-none" style={{zIndex:1100}}>
      <div className="container mt-4">
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 text-center">
            <a href="/" className="me-3"><img src={psKraftwagenLogoWeiss} alt="PS Kraftwagen Logo" style={{width:100}} /></a>
          </div>
          <div className="col-2 text-end">
            <div className="fas fa-times fa-2x text-white mt-3 me-3" onClick={() => setShowBurgerMenu(false)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <Link className="my-3 h3 text-white d-block" to="/">Home</Link>
            <Link className="my-3 h3 text-white d-block" to="/ankauf">Ankauf</Link>
            <Link className="my-3 h3 text-white d-block" to="/verkauf">Verkauf</Link>
            <Link className="my-3 h3 text-white d-block" to="/aufbereitung">Aufbereitung</Link>
            <Link className="my-3 h3 text-white d-block" to="/mietfahrzeuge">Mietfahrzeuge</Link>
            <Link className="my-3 h3 text-white d-block" to="/service">Service</Link>
            <Link className="my-3 h3 text-white d-block" to="/team">Team</Link>
            <button className="btn btn-primary border mt-2" data-bs-toggle="modal" data-bs-target="#primeModal" onClick={() => setShowBurgerMenu(false)}>
              <div className="row align-items-center">
                <div className="col-3 text-end">
                  <i className="fas fa-shield-alt fa-2x" />
                </div>
                <div className="col-9 text-start">
                  <div className="small fw-bold text-uppercase">Kraftwagen Trust</div>
                  <div className="small fw-light">Zahlung &amp; Lieferung</div>
                </div>
              </div>
            </button><br/>
            <a href="https://seu2.cleverreach.com/f/295165-295905/" target="_blank" rel="noreferrer" className="btn btn-primary border mt-2">
              <div className="row align-items-center">
                <div className="col-3 text-end">
                  <i className="fas fa-envelope-open fa-2x me-5" />
                </div>
                <div className="col-9 text-start">
                  <div className="small fw-bold text-uppercase">Newsletter</div>
                  <div className="small fw-light">Anmeldung</div>
                </div>
              </div>
            </a>

            <div className="mt-5" />
            <p className="text-white fw-bold">PS Kraftwagen GmbH</p>
            <Link className="h6 text-white d-block" to="/team">Kontakt</Link>
            <Link className="h6 text-white d-block" to="/datenschutz">Datenschutz</Link>
            <Link className="h6 text-white d-block" to="/impressum">Impressum</Link>
          </div>
        </div>
      </div>      
    </div>
    : null}

    <div className="d-lg-none container sticky-top bg-white py-2" style={{zIndex:99}}>
      <div className="container mt-2">
        <div className="row">
          <div className="col-10">
            <a href="/" className=""><img src={psKraftwagenLogoHorizontal} alt="PS Kraftwagen Logo" style={{width:190}} /></a>
          </div>
          <div className="col-2 text-center">
            <i className="fas fa-bars fa-2x mt-2" onClick={() => setShowBurgerMenu(true)} />
          </div>
        </div>
      </div>
    </div>

    <div className="d-none d-lg-block container-fluid bg-white py-4 border-top shadow">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-xl-2 text-lg-center py-lg-3">
            <a href="/"><img src={psKraftwagenLogoHorizontal} alt="PS Kraftwagen Logo" style={{width:200}} /></a>
          </div>
          <div className="col-9 col-lg-7 col-xl-6 text-start text-xl-end">
            <Link to="/ankauf" className="me-3 h4 fw-normal">Ankauf</Link>
            <Link to="/verkauf" className="me-3 h4 fw-normal">Verkauf</Link>
            <Link to="/aufbereitung" className="me-3 h4 fw-normal">Aufbereitung</Link>
            <Link to="/mietfahrzeuge" className="me-3 h4 fw-normal">Mietfahrzeuge</Link>
            <Link to="/service" className="me-3 h4 fw-normal">Service</Link>
            <Link to="/team" className="me-3 h4 fw-normal">Team</Link>
          </div>
          <div className="col-3 col-lg-5 col-xl-4 text-end">
            <button className="btn btn-sm btn-secondary me-2" data-bs-toggle="modal" data-bs-target="#primeModal">
              <div className="row align-items-center">
                <div className="col-2">
                  <i className="fas fa-shield-alt fa-2x" />
                </div>
                <div className="col-10">
                  <div className="small fw-bold mt-2 text-uppercase">Kraftwagen Trust</div>
                  <div className="small fw-light">Zahlung &amp; Lieferung</div>
                </div>
              </div>
            </button>
            <a href="https://seu2.cleverreach.com/f/295165-295905/" target="_blank" rel="noreferrer" className="btn btn-sm btn-primary">
              <div className="row align-items-center">
                <div className="col-2">
                  <i className="fas fa-envelope-open fa-2x" />
                </div>
                <div className="col-10">
                  <div className="small fw-bold mt-2 text-uppercase">Newsletter</div>
                  <div className="small fw-light">Anmeldung</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Header;

const Quote = ({author, bgColor, color, children, imgAlt, imgSrc, title}) => <>
  <div className={`container-fluid mb-5 py-5 bg-${bgColor} bg-gradient`}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-9 col-md-3 col-lg-2 text-center">
          <img src={imgSrc} alt={imgAlt} className="rounded-circle shadow img-fluid"  />
        </div>
        <div className="col-12 col-md-8">
          <div className={`h2 text-${color} fw-bold mt-3 mt-md-4 ms-md-4`}>{title}</div>
          <p className={`h4 text-${color} fw-light mt-3 ms-md-4`}>
            <i className={`fas fa-angle-double-right text-${color} me-1`} />{children}
          </p>
          <div className={`h5 text-${color} fw-light ms-md-4`}>- {author}</div>
        </div>
      </div>
    </div>
  </div>
</>

export default Quote;
import {useEffect} from 'react';

import ContactBar from '../components/ContactBar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import portraitCanerSoyak from '../assets/images/portrait-caner-soyak.jpg';
// import portraitPlaceholderLogo from '../assets/images/portrait-placeholder-logo.jpg';
import portraitSebastianMerkel from '../assets/images/portrait-sebastian-merkel.jpg';
import portraitMariaCarhat from '../assets/images/portrait-maria-carhat.jpg';
import sebastianMerkelAmArbeitsplatz from '../assets/images/sebastian-merkel-am-arbeitsplatz.jpg';
import Quote from '../components/Quote';
import StatisticBar from '../components/StatisticBar';

const Team = () => {
  
  useEffect(() => {
    const pageTitle = 'PS Kraftwagen GmbH | Team';

    document.title = pageTitle;

    window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)]);
    window._paq.push(['setDocumentTitle', pageTitle]);
    window._paq.push(['trackPageView']);
  });

  return <>
    <Header />

    <div className="container-fluid py-lg-5 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 order-last order-lg-first">
            <h1 className="mt-5 text-uppercase text-secondary fw-bolder">Wir freuen uns auf Ihren Anruf</h1>

            <div className="row align-items-center mt-5 text-secondary">
              <div className="col-2 col-lg-1"><i className="fas fa-envelope fa-2x" /></div>
              <div className="col-10 col-lg-11"><a href="mailto:info@pskraftwagen.de" className="text-decoration-none fw-bolder h4">info@pskraftwagen.de</a></div>
            </div>
            <div className="row align-items-center mt-3 text-secondary">
              <div className="col-2 col-lg-1"><i className="fas fa-phone fa-2x" /></div>
              <div className="col-10 col-lg-11"><a href="tel:+4971618085101" className="text-decoration-none fw-bolder h4">07161 808 51 01</a></div>
            </div>
          </div>
          <div className="col-12 col-lg-7 order-first order-lg-last">
            <img src={sebastianMerkelAmArbeitsplatz} alt="Sebastian Merkel" className="img-fluid rounded shadow" />
          </div>
        </div>
      </div>
    </div>

    <Quote imgSrc={portraitSebastianMerkel} imgAlt="Portrait Sebastian Merkel" title="Das PS Kraftwagen Team" author="Sebastian Merkel" bgColor="secondary" color="white">
      Wir sind stolz darauf Kunden zu haben die uns für unsere Arbeit &amp; Bemühungen immer wieder weiterempfehlen
    </Quote>

    <div className="container-fluid my-5">
      <div className="container">
        <div className="row h-100 align-items-center">
          <div className="col-12 text-center">
            <h3 className="mt-5 text-secondary fw-bolder">Das PS Kraftwagen Team</h3>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 mt-4 mt-md-0">
            <img src={portraitSebastianMerkel} alt="Portrait Sebastian Merkel" className="img-fluid rounded-top" />
            <div className="shadow rounded-bottom p-4">
              <div className="h3 text-secondary">Sebastian Merkel</div>
              <div className="h4 text-primary">Ankauf &amp; Verkauf</div>
              <p className="mt-4">Ich bin für den Ankauf und Verkauf der Fahrzeuge in unserem Bestand zuständig &amp; administriere die Inserate die online angeboten werden.</p>
              <div className="border-top mt-4 pt-3">
                <a href="https://www.facebook.com/pskraftwagen" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-facebook-square fa-2x" /></a>
                <a href="https://www.instagram.com/pskraftwagen" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-instagram-square fa-2x" /></a>
                <a href="https://api.whatsapp.com/send/?phone=+4915206348058&text&app_absent=0" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-whatsapp-square fa-2x" /></a>
                <a href="https://www.linkedin.com/in/sebastian-merkel-6610a3200/" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-linkedin fa-2x" /></a>
                <a href="tel:+4971618082616" target="_blank" rel="noreferrer" className="text-secondary"><i className="fas fa-fw fa-phone-square fa-2x" /></a>
                <a href="mailto:sm@pskraftwagen.de" target="_blank" rel="noreferrer" className="text-secondary"><i className="fas fa-fw fa-envelope fa-2x" /></a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-4 mt-md-0">
            <img src={portraitMariaCarhat} alt="Portrait Maria Carhat" className="img-fluid rounded-top" />
            <div className="shadow rounded-bottom p-4">
              <div className="h3 text-secondary">Maria Carhat</div>
              <div className="h4 text-primary">Verkaufsassistenz</div>
              <p className="mt-4">Meine Hauptaufgabe ist die Pflege unseres Fahrzeugbestandes und die Assistenz im Verkauf.<br/><br/></p>
              <div className="border-top mt-4 pt-3">
                <a href="https://www.facebook.com/pskraftwagen" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-facebook-square fa-2x" /></a>
                <a href="https://www.instagram.com/pskraftwagen" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-instagram-square fa-2x" /></a>
                <a href="https://www.linkedin.com/company/ps-kraftwagen-gmbh/" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-linkedin fa-2x" /></a>
                <a href="tel:+4971618082873" target="_blank" rel="noreferrer" className="text-secondary"><i className="fas fa-fw fa-phone-square fa-2x" /></a>
                <a href="mailto:mc@pskraftwagen.de" target="_blank" rel="noreferrer" className="text-secondary"><i className="fas fa-fw fa-envelope fa-2x" /></a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-4 mt-md-0">
            <img src={portraitCanerSoyak} alt="Portrait Caner Soyak" className="img-fluid rounded-top" />
            <div className="shadow rounded-bottom p-4">
              <div className="h3 text-secondary">Caner Soyak</div>
              <div className="h4 text-primary">Professionelle Autopflege</div>
              <p className="mt-4">Für die Pflege unserer Bestandsfahrzeuge, bin ich zuständig.<br/><br/><br/></p>
              <div className="border-top mt-4 pt-3">
                <a href="https://www.facebook.com/pskraftwagen" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-facebook-square fa-2x" /></a>
                <a href="https://www.instagram.com/pskraftwagen" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-instagram-square fa-2x" /></a>
                <a href="https://www.linkedin.com/company/ps-kraftwagen-gmbh/" target="_blank" rel="noreferrer" className="text-secondary"><i className="fab fa-fw fa-linkedin fa-2x" /></a>
                <a href="tel:+4971618082618" target="_blank" rel="noreferrer" className="text-secondary"><i className="fas fa-fw fa-phone-square fa-2x" /></a>
                <a href="mailto:aufbereitung@pskraftwagen.de" target="_blank" rel="noreferrer" className="text-secondary"><i className="fas fa-fw fa-envelope fa-2x" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <StatisticBar theme="secondary" />

    <ContactBar variant="light" />

    <Footer />
  </>
}

export default Team;

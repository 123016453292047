import {useContext, useEffect} from "react";
import StoreContext from '../storeContext';

const SyncVehicles = () => {
  const {state, dispatch} = useContext(StoreContext);

  useEffect(() => {
    async function fetchVehicles() {
      const request = await fetch('/vehicles.json');
      const response = await request.json();

      dispatch({type: 'UPDATE_STORE', payload: {
        vehicles: response,
        vehiclesFetched: true,
      }});
    }
    
    if (!state.vehiclesFetched) {
      fetchVehicles();
    }
  });

  return <></>
}

export default SyncVehicles;
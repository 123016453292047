const ContactBar = ({title = 'Sie möchten Ihr Fahrzeug zu einem fairen Preis verkaufen?', variant = 'dark'}) => {

  const variants = {
    dark: {
      backgroundClasses: 'container-fluid py-5 bg-secondary bg-gradient text-white',
      linkClasses: 'text-decoration-none text-white bg-transparent border-0',
    },
    light: {
      backgroundClasses: 'container-fluid py-5 text-secondary',
      linkClasses: 'text-decoration-none text-secondary bg-transparent border-0',
    }
  }

  return <>
    <div className={variants[variant].backgroundClasses}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-9 text-center">
            <div className="mt-4 fw-bolder h1">{title}</div>
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          <div className="col-12 col-md-3 text-center">
            <a href="/ankauf" className={variants[variant].linkClasses}>
              <i className="fas fa-tasks fa-5x d-block mt-5 mt-md-0" />
              <span className={`btn fw-bolder d-block mt-3 ${variants[variant].linkClasses}`}>Fahrzeugbewertung starten</span>
            </a>
          </div>
          <div className="col-12 col-md-3 text-center">
            <a href="tel:+4971618085101" className={variants[variant].linkClasses}>
              <i className="fas fa-phone fa-5x d-block mt-5 mt-md-0" />
              <span className={`btn fw-bolder d-block mt-3 ${variants[variant].linkClasses}`}>07161 808 51 01</span>
            </a>
          </div>
          <div className="col-12 col-md-3 text-center">
            <a href="https://api.whatsapp.com/send/?phone=+4915206348058&text&app_absent=0" className={variants[variant].linkClasses}>
              <i className="fab fa-whatsapp fa-5x d-block mt-5 mt-md-0" />
              <span className={`btn fw-bolder d-block mt-3 ${variants[variant].linkClasses}`}>01520 / 6348058</span>
            </a>
          </div>
          <div className="col-12 col-md-3 text-center">
            <a href="mailto:info@pskraftwagen.de" className={variants[variant].linkClasses}>
              <i className="fas fa-envelope fa-5x d-block mt-5 mt-md-0" />
              <span className={`btn fw-bolder d-block mt-3 ${variants[variant].linkClasses}`}>info@pskraftwagen.de</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default ContactBar;
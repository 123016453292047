import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import Footer from '../components/Footer';
import Header from '../components/Header';
import videoPreviewMehmedHatip from '../assets/images/video-preview-mehmed-hatip.jpg';
import portraitMehmedHatip from '../assets/images/portrait-mehmed-hatip.jpg';
import ProgressStepper, {ProgressStep} from '../components/ProgressStepper';
import Quote from '../components/Quote';
import StoreContext from '../storeContext';

const Aufbereitung = () => {
  const [expandedExtras, setExpandedExtras] = useState([]);
  const [formDate, setFormDate] = useState('');
  const [formFirstname, setFormFirstname] = useState('');
  const [formLastname, setFormLastname] = useState('');
  const [formMail, setFormMail] = useState('');
  const [formPhone, setFormPhone] = useState('');
  const [formSend, setFormSend] = useState(false);
  const [formSendError, setFormSendError] = useState(false);
  const [formTime, setFormTime] = useState('');
  const [partnerIdentCode, setPartnerIdentCode] = useState('');
  const [partnerIdentCodeInvalid, setPartnerIdentCodeInvalid] = useState(null);
  const [progressStep1, setProgressStep1] = useState(null);
  const [progressStep2, setProgressStep2] = useState(null);
  const [progressStep3, setProgressStep3] = useState(null);
  const [showOpeningHours, setShowOpeningHours] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const {dispatch, state} = useContext(StoreContext);
  const [vehicleClass, setVehicleClass] = useState(null);
  const ceramicIds = ['car_individual_7', 'car_silber_7', 'car_platin_5', 'car_gold_5', 'suv_individual_7', 'suv_silber_7', 'suv_platin_5', 'suv_gold_5'];

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase(); 

    if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const pageTitle = 'PS Kraftwagen GmbH | Aufbereitung';

    document.title = pageTitle;

    window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)]);
    window._paq.push(['setDocumentTitle', pageTitle]);
    window._paq.push(['trackPageView']);

    if (isSafari()) {
      setFormDate(new Date().toISOString().split('T').shift());
      setFormTime('12:00');
    } 

    if (!vehicleClass && !selectedPackage) {
      setProgressStep1({isActive: true, isDone: false, onClickCallback: () => {}});
      setProgressStep2({isActive: false, isDone: false, onClickCallback: () => {}});
      setProgressStep3({isActive: false, isDone: false, onClickCallback: () => {}});
    }

    else if (vehicleClass && !selectedPackage) {
      setProgressStep1({isActive: false, isDone: true, onClickCallback: () => {setVehicleClass(null)}});
      setProgressStep2({isActive: true, isDone: false});
      document.getElementById('progressStepper').scrollIntoView();
    }

    else if (vehicleClass && selectedPackage) {
      setProgressStep1({isActive: false, isDone: true, onClickCallback: () => {setVehicleClass(null); setSelectedPackage(null)}});
      setProgressStep2({isActive: false, isDone: true, onClickCallback: () => {setSelectedPackage(null)}});
      setProgressStep3({isActive: true, isDone: false});
      document.getElementById('progressStepper').scrollIntoView();
    }

  }, [vehicleClass, selectedPackage]);

  const isPriceEstimated = (packageId) => {
    const foundPackage = state.preparation[vehicleClass].packages.find(pack => pack.id === packageId);
    const findExtraRegEx = new RegExp(`${packageId}_`);
    const packageRelevantExtras = selectedExtras.filter(selEx => selEx.match(findExtraRegEx));
    const foundRelevantExtras = foundPackage.extras.items.filter(extra => packageRelevantExtras.includes(extra.id));
    const isEstimated = foundRelevantExtras.filter(extra => extra.estimatedPrice).length > 0 ? true : false;

    return isEstimated;
  }

  const calculatePrice = (packageId, discount = true) => {
    const foundPackage = state.preparation[vehicleClass].packages.find(pack => pack.id === packageId);
    const findExtraRegEx = new RegExp(`${packageId}_`);
    const packageRelevantExtras = selectedExtras.filter(selEx => selEx.match(findExtraRegEx));
    const foundRelevantExtras = foundPackage.extras.items.filter(extra => packageRelevantExtras.includes(extra.id));

    let price = foundPackage.price;

    foundRelevantExtras.forEach(extra => price = extra.price+price);

    if (state.partner && discount) {
      price = price-(price*state.partner.discount_in_percent/100);
    }

    return price;
  }

  const packageNumber = () => {
    const vehicleClassTitle = vehicleClass.slice(0,1).toUpperCase();
    const selectedPackageTitle = selectedPackage.title.slice(0,2).toUpperCase();
    const selectedExtrasTitle = selectedExtras.map(extra => extra.split('_').pop()).join('').padStart(7, '0');

    return `${vehicleClassTitle}${selectedPackageTitle}-${selectedExtrasTitle}`;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const findExtraRegEx = new RegExp(`${selectedPackage.id}_`);
    const packageRelevantExtras = selectedExtras.filter(selEx => selEx.match(findExtraRegEx));

    if (new Date(formDate).getDay() === 0) {
      setShowOpeningHours(true);
      return false;
    }
    else if (new Date(formDate).getDay() === 6 && (parseFloat(formTime.replace(':', '.')) < 9.00 || parseFloat(formTime.replace(':', '.')) > 14.00)) {
      setShowOpeningHours(true);
      return false;
    }
    else if ([1,2,3,4,5].includes(new Date(formDate).getDay()) && (parseFloat(formTime.replace(':', '.')) < 8.00 || parseFloat(formTime.replace(':', '.')) > 18.00)) {
      setShowOpeningHours(true);
      return false;
    }
    else {
      setShowOpeningHours(false);
    }

    const request = await fetch(`${process.env.REACT_APP_KK_HOST}/preparation?apiKey=${process.env.REACT_APP_KK_APIKEY_WITHOUT_USER_ID}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstname: formFirstname,
        lastname: formLastname,
        requestedDate: `${formDate} ${formTime}`,
        email: formMail,
        phone: formPhone,
        packageNumber: packageNumber(),
        subject: 'Aufbereitung',
        calculatedPrice: calculatePrice(selectedPackage.id),
        selectedPackageTitle: selectedPackage.title,
        selectedPackagePrice: selectedPackage.price,
        selectedPackageCategories: selectedPackage.categories ? selectedPackage.categories : [],
        selectedExtras: packageRelevantExtras.map(selEx => selectedPackage.extras.items.find(extra => selEx === extra.id)),
        vehicleClass: vehicleClass,
      }),
    });

    if (request.status === 201) {
      setFormSend(true);
      document.getElementById('terminanfrage-abgesendet').scrollIntoView();
    } else {
      setFormSendError(true);
      document.getElementById('terminanfrage-fehler').scrollIntoView();
    }
  }

  const handlePartnerIdentification = async (event) => {
    event.preventDefault();

    const request = await fetch(`${process.env.REACT_APP_KK_HOST}/customer/ident/${partnerIdentCode}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (request.status === 200) {
      const partner = await request.json();

      dispatch({type: 'UPDATE_STORE', payload: {
        partner,
      }});
      
      setPartnerIdentCodeInvalid(false);
      setFormFirstname(partner.firstname);
      setFormLastname(partner.lastname);
      setFormMail(partner.email);
      setFormPhone(partner.phone);
    } else {
      setPartnerIdentCodeInvalid(true);
    }

  }

  const getExtras = (extra) => {
    const priceFormatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(extra.price);
    const isActive = selectedExtras.find(selEx => selEx === extra.id);
    const onClick = () => setSelectedExtras(selectedExtras.indexOf(extra.id) < 0 ? 
      [...selectedExtras, extra.id] : 
      selectedExtras.filter(selEx => selEx !== extra.id));

    return <React.Fragment key={extra.id}>
      <div className="mt-1 p ms-1 cursor-pointer" onClick={onClick}>
        <div className="row">
          <div className="col-2 px-3">
            <div className="ms-2">
              {isActive ? <i className="far fa-check-square text-primary fa-1x" /> : <i className="far fa-square text-primary fa-1x" />}
            </div>
          </div>
          <div className="col-10 p-0">
            <div className="pe-3">
              {extra.title} 
              <span className="text-muted small">
                {extra.estimatedPrice ? <span className="fw-bold"> ab</span> : null} +{priceFormatted}
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  }

  const getDependendExtras = (extra, extras) => {
    const isActive = selectedExtras.find(selEx => selEx === extra.id);
    const isSelectable = selectedExtras.find(selEx => selEx === extra.dependsOn);
    const foundDependsOnExtra = extra.dependsOn ? extras.items.find(item => item.id === extra.dependsOn) : undefined;
    const onClick = isSelectable ? 
      () => setSelectedExtras(selectedExtras.indexOf(extra.id) < 0 ? [...selectedExtras, extra.id] : selectedExtras.filter(selEx => selEx !== extra.id)) : 
      () => false;

    // Lösche alle abhängigen Extras wenn das Hauptextra abgewählt wird.
    if (isActive && !isSelectable) {
      setSelectedExtras(selectedExtras.filter(selEx => selEx !== extra.id));
    }

    return <React.Fragment key={extra.id}>
      <div className={`mt-1 p ms-1 ${isSelectable ? 'cursor-pointer' : 'text-muted'}`} onClick={onClick}>
        <div className="row">
          <div className="col-2 px-3">
            <div className="ms-2">
              {isActive ? <i className="far fa-check-square text-primary fa-1x" /> : <i className="far fa-square text-primary fa-1x" />}
            </div>
          </div>
          <div className="col-10 p-0">
            <div className={`pe-3 ${!isSelectable ? 'ps-tooltip' : ''}`}>
              {!isSelectable ? <span className="tooltiptext">Abhängig von {foundDependsOnExtra.title}</span> : null}
              {extra.title}
              <span className="text-muted small">
                {extra.estimatedPrice ? <span className="fw-bold"> ab</span> : null} +{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(extra.price)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  }

  return <>
    <Header />

    <div className="modal fade" id="commercialVehicleModal" tabIndex="-1" aria-labelledby="commercialVehicleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="commercialVehicleModalLabel">Auf Anfrage</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            Nutz- und Geschäftsfahrzeuge bedürfen einer vorherigen Absprache mit uns. Nehmen Sie einfach Kontakt mit uns auf.
            <a href="tel:+4971618085101" className="d-block text-center mt-4 border bg-light p-3">
              <i className="fas fa-phone fa-1x pe-2" />
              <span className="h4">07161 808 51 01</span>
            </a>
            <a href="https://api.whatsapp.com/send/?phone=+4915206348058&text&app_absent=0" className="d-block text-center mt-2 border bg-light p-3">
              <i className="fab fa-whatsapp fa-1x pe-2" />
              <span className="h4">01520 / 6348058</span>
            </a>
            <a href="mailto:info@pskraftwagen.de" className="d-block text-center mt-2 border bg-light p-3">
              <i className="fas fa-envelope fa-1x pe-2" />
              <span className="h4">info@pskraftwagen.de</span>
            </a>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ok, verstanden</button>
          </div>
        </div>
      </div>
    </div>

    <form onSubmit={handlePartnerIdentification} className="modal fade" id="partner-modal" tabIndex="-1" aria-labelledby="partner-modal-label" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="commercialVehicleModalLabel">PS Kraftwagen Partner</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            
            {!state.partner &&
            <div className="">
              Bitte geben Sie Ihren persönlichen <b>Identifizierungscode</b> ein.
              <input type="text" className="my-4 form-control form-control-lg" placeholder="Identifizierungscode" value={partnerIdentCode} onChange={(e) => setPartnerIdentCode(e.target.value)} autoFocus pattern="\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b" />
            </div>
            }

            {partnerIdentCodeInvalid && <div className="bg-danger p-3 rounded text-white fw-bold">
              Der Identifizierungscode ist ungültig.
            </div>}

            {state.partner &&
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <span className="h4 text-secondary"><i className="fas fa-user-check" /> Willkommen {state.partner?.salutation} {state.partner?.firstname} {state.partner?.lastname}!</span>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="mt-3">
                    Wir haben Ihre Einstellungen geladen. <br/>
                    Ihr Rabatt von <span className="fw-bold">{new Intl.NumberFormat('de-DE').format(state.partner?.discount_in_percent)}%</span> wird auf die Endpreise angerechnet.<br/>
                    <br/>
                    Ihre Kontaktdaten werden automatisch vom System ausgefüllt. 
                  </p>
                  <p className="border-top pt-3">
                    {state.partner?.company_name}<br/>
                    {state.partner?.salutation} {state.partner?.firstname} {state.partner?.lastname}<br/>
                    {state.partner?.phone}<br/>
                    {state.partner?.email}<br/>
                  </p>
                </div>
              </div>
            </div>
            }
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-link" data-bs-dismiss="modal">Abbrechen</button>
            {state.partner ? <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Schließen</button> : <button type="submit" className="btn btn-secondary">Absenden</button>}
          </div>
        </div>
      </div>
    </form>

    <div className="container-fluid py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 order-last order-lg-first">
            <h1 className="mt-5 text-uppercase text-secondary fw-bolder">Fahrzeug Aufbereitung</h1>
            <h4 className="text-secondary"><i className="fas fa-hand-sparkles" /> powered by Mehmed Hatip</h4>
            <p className="mt-3">
              Wir sind Ihr Ansprechpartner für die Fahrzeugaufbereitung in Uhingen und Umgebung. Ihr
              Fahrzeug ist bei uns in besten Händen. Das garantieren wir durch unsere langjährige Erfahrung und
              geschultes Personal. Stellen Sie sich Ihre Auto Aufbereitung zusammen.
            </p>
          </div>
          <div className="col-12 col-lg-7 order-first order-lg-last">
            <video width="100%" poster={videoPreviewMehmedHatip} controls>
              <source src="/video-aufbereitung.mp4" type="video/mp4" />
              Ihr Browser unterstützt das Video-Tag nicht.
            </video>
          </div>
        </div>
      </div>
    </div>

    <Quote imgSrc={portraitMehmedHatip} imgAlt="Portrait Mehmed Hatip" title="Das PS Kraftwagen Team" author="Mehmed Hatip" bgColor="secondary" color="white">
      Bei uns wird jedes Fahrzeug mit besonders viel Liebe zum Detail gereinigt
    </Quote>

    {formSend ?
    <div className="container-fluid" id="terminanfrage-abgesendet">
      <div className="container bg-success rounded shadow text-white p-4">
        <div className="row justify-content-center">
          <div className="col-6 text-center">
            <i className="fas fa-rocket fa-4x" />
            <h3 className="fw-bolder mt-3">Terminanfrage abgesendet</h3>
            <p>
              Vielen Dank für Ihre Terminanfrage {formFirstname} {formLastname}. 
              Wir bearbeiten Ihre Anfrage und werden uns schnellstmöglich mit Ihnen in Verbindung setzen, 
              um den gewünschten Termin zu bestätigen.
            </p>
            <Link to="/" className="btn btn-light fw-bold mt-4">zurück zur Startseite</Link>
          </div>
        </div>
      </div>
    </div> : null}

    {formSendError ?
    <div className="container-fluid" id="terminanfrage-fehler">
      <div className="container bg-danger rounded shadow text-white p-4">
        <div className="row justify-content-center">
          <div className="col-6 text-center">
            <i className="fas fa-sad-tear fa-4x" />
            <h3 className="fw-bolder mt-3">Technischer Fehler</h3>
            <p>
              Hups, ein Fehler 500 ist aufgetreten.
              Das hätte nicht passieren dürfen. Bitte versuchen Sie es noch einmal oder kontaktieren uns telefonisch.
            </p>
            <p>
              Gerne dürfen Sie uns über diesen Fehler informieren <a href="mailto:support@pskraftwagen.de?Subject=Seite Aufbereitung - Fehler 500" className="text-white">support@pskraftwagen.de</a>
            </p>
            <a href="/aufbereitung" className="btn btn-light fw-bold mt-4">Seite neu laden</a>
          </div>
        </div>
      </div>
    </div> : null}

    {!formSend && !formSendError ? <React.Fragment>

    <div className="container-fluid">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <h3 className="text-secondary fw-bolder">Aufbereitung konfigurieren</h3>
            <p>Bitte wählen Sie Ihre Fahrzeugklasse aus um zum nächsten Schritt zu gelangen.</p>
          </div>
        </div>
      </div>
    </div>    

    <ProgressStepper>
      <ProgressStep id="1" description={vehicleClass && vehicleClass === 'car' ? 'PKW' : vehicleClass === 'suv' ? 'SUV' : ''} isActive={progressStep1 && progressStep1.isActive} isDone={progressStep1 && progressStep1.isDone} onClickCallback={progressStep1 && progressStep1.onClickCallback}>1. Auswahl Fahrzeugklasse</ProgressStep>
      <ProgressStep id="2" description={selectedPackage && selectedPackage.title} isActive={progressStep2 && progressStep2.isActive} isDone={progressStep2 && progressStep2.isDone} onClickCallback={progressStep2 && progressStep2.onClickCallback}>2. Wählen Sie ein Paket</ProgressStep>
      <ProgressStep id="3" description="" isActive={progressStep3 && progressStep3.isActive} isDone={progressStep3 && progressStep3.isDone} onClickCallback={progressStep3 && progressStep3.onClickCallback}>3. Wählen Sie einen Termin</ProgressStep>
    </ProgressStepper>

    {!vehicleClass && !selectedPackage ? <>
      <div className="container-fluid">
        <div className="container">
          <div className="text-center h2 my-5">Auswahl Fahrzeugklasse</div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="shadow rounded text-center mt-3 mt-lg-0 p-4 text-secondary" onClick={() => setVehicleClass('car')}>
                <i className="fa fa-car-side fa-5x text-primary" />
                <div className="h3 fw-bold mt-3">PKW</div>
                <button type="button" className="mt-4 btn btn-secondary px-5 border-white">
                  <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Auswählen</span>
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="shadow rounded text-center mt-3 mt-lg-0 p-4 text-secondary" onClick={() => setVehicleClass('suv')}>
                <i className="fa fa-truck-pickup fa-5x text-primary" />
                <div className="h3 fw-bold mt-3">SUV</div>
                <button type="button" className="mt-4 btn btn-secondary px-5 border-white">
                  <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Auswählen</span>
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="shadow rounded text-center mt-3 mt-lg-0 p-4 text-secondary">
                <i className="fa fa-truck fa-5x text-primary" />
                <div className="h3 fw-bold mt-3">Nutzfahrzeuge</div>
                <button type="button" className="mt-4 btn btn-secondary px-5 border-white" data-bs-toggle="modal" data-bs-target="#commercialVehicleModal">
                  <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Auswählen</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-end">
              <button type="button" className="mt-4 btn btn-link px-5 border-white" data-bs-toggle="modal" data-bs-target="#partner-modal">
                <i className="fas fa-chevron-right me-1" /> <span className="fw-bold">Ich bin PS Kraftwagen Partner</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </> : null}

    {vehicleClass && !selectedPackage ? <>
      <div className="container-fluid mt-5">
        <div className="container">
          <div className="row">
            {state.preparation[vehicleClass].packages.map(pack => <React.Fragment key={pack.title}>
              <div className={`col-12 col-md-6 col-lg-3 ${pack.isRecommended ? 'mt-4 mt-lg-n4' : ''}`}>
                <div className="text-center" style={{minHeight: 70}}>
                  {pack.isRecommended ? <><i className="fas fa-star fa-2x text-warning d-block" /> <div className="mt-1">Top Seller</div></> : null}
                </div>
                <div className={`shadow rounded text-center text-secondary mt-3 mt-lg-0 ${pack.isRecommended ? '' : ''}`}>
                  <div className="rounded-top p-3" style={pack.backgroundColor ? {backgroundColor: pack.backgroundColor, color: pack.color} : {}}>
                    <span className="h3 fw-bold d-block">
                      {pack.title}
                    </span>
                    {pack.workingTime ? <span className="small fw-bold"><i className="far fa-clock pe-1 text-primary" /> Dauer {pack.workingTime}</span> : <>&nbsp;</>}
                  </div>
                  {pack.categories && pack.categories.map(category =>
                    <div key={category.title} className="text-start p-3">
                      <p className="fw-bold h4">{category.title}</p>
                      {category.items.map(categoryItem => 
                        <div className="row mt-1" key={categoryItem}>
                          <div className="col-2 col-xl-2"><i className="fas fa-chevron-circle-right me-1" style={pack.backgroundColor ? {color: pack.backgroundColor} : {}} /></div>
                          <div className="col-10 ps-0 col-xl-10"><span className="me-2">{categoryItem}</span></div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="text-start mt-3">
                    {pack.extras.title ? 
                      <p className="fw-bold bg-info py-2 px-3" onClick={() => setExpandedExtras(expandedExtras.indexOf(pack.id) < 0 ? [...expandedExtras, pack.id] : expandedExtras.filter(selPa => selPa !== pack.id))}>
                        {expandedExtras.includes(pack.id) ? 
                          <><i className="fas fa-chevron-circle-up me-1" /> {pack.extras.title}</> : 
                          <><i className="fas fa-chevron-circle-down me-1" /> {pack.extras.title}</>}
                      </p> : null}
                    <div className={`${pack.extras.collapsed && !expandedExtras.includes(pack.id) ? 'd-none' : ''}`}>
                      {pack.extras.items.map(extra => !extra.dependsOn ? getExtras(extra) : null)}
                      {pack.extras.items.map(extra => extra.dependsOn ? getDependendExtras(extra, pack.extras) : null)}
                    </div>
                  </div>
                  <div className="text-end mt-4 me-3">
                    <i className="fas fa-check text-info fa-1x" /><span className="fw-bold h4"> {isPriceEstimated(pack.id) ? <span>ab</span> : null} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(calculatePrice(pack.id))}</span>
                  </div>
                  <button type="button" disabled={calculatePrice(pack.id) === 0 ? 'disabled' : ''} className="my-4 btn btn-secondary btn-lg px-5 border-white" onClick={() => setSelectedPackage(pack)}>
                    <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Auswählen</span>
                  </button>
                </div>
              </div>
            </React.Fragment>)}
          </div>
        </div>
      </div>
    </> : null}

    {vehicleClass && selectedPackage ? <>
      <form onSubmit={handleSubmit}>
        <div className="container-fluid mt-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="shadow rounded text-center text-secondary pb-3">
                  <div className="rounded-top p-3" style={selectedPackage.backgroundColor ? {backgroundColor: selectedPackage.backgroundColor, color: selectedPackage.color} : {}}>
                    <span className="h3 fw-bold d-block">{selectedPackage.title}</span>
                    {selectedPackage.workingTime ? <span className="small fw-bold"><i className="far fa-clock pe-1 text-primary" /> Dauer {selectedPackage.workingTime}</span> : <>&nbsp;</>}
                  </div>
                  {selectedPackage.categories && selectedPackage.categories.map(category =>
                    <div key={category.title} className="text-start p-3">
                      <p className="fw-bold">{category.title}</p>
                      {category.items.map(categoryItem =>
                        <div key={categoryItem} className="mt-1 p"><i className="fas fa-chevron-circle-right me-1 text-secondary" /> <span className="me-2">{categoryItem}</span></div>
                      )}
                    </div>
                  )}
                  <div className="text-start mt-3">
                    {selectedPackage.extras.title ? <p className="fw-bold bg-info py-2 px-3">{selectedPackage.extras.title}</p> : null}
                    {selectedPackage.extras.items.map(extra =>
                      <div key={extra.id} className="mt-1 p">
                        <div className="row">
                          <div className="col-2 px-3">
                            <div className="ms-2">
                              {selectedExtras.find(selEx => selEx === extra.id) ? <i className="fas fa-check text-primary fa-1x" /> : <i className="fas fa-minus text-primary fa-1x" />}
                            </div>
                          </div>
                          <div className="col-10 p-0">
                            {selectedExtras.find(selEx => selEx === extra.id) ? <div className="pe-3 fw-bold text-success">
                              {extra.title} <span className="text-muted small">{extra.estimatedPrice ? <span>ab</span> : null} +{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(extra.price)}</span>
                            </div> : 
                            <div className="pe-3 text-muted">
                              {extra.title} <span className="text-muted small">{extra.estimatedPrice ? <span>ab</span> : null} +{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(extra.price)}</span>
                            </div>}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="text-end mt-4 me-3">
                    <i className="fas fa-check text-info fa-1x" /><span className="fw-bold h4"> {isPriceEstimated(selectedPackage.id) ? <span>ab</span> : <span>für</span>} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(calculatePrice(selectedPackage.id))}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 px-lg-5">
                <div className="h2 text-secondary text-center text-lg-end mt-4 mt-lg-0">Ihr individuelles Fahrzeug Aufbereitungs Paket</div>
                <div className="row mt-5">
                  <div className="col-10 text-end">
                    <div className=""><i className="fas fa-check text-primary fa-1x" /> Grundpreis Paket {selectedPackage.title} ({vehicleClass === 'car' ? 'Auto' : 'SUV'})</div>
                    {selectedPackage.extras.items.map(extra => 
                      selectedExtras.find(selEx => selEx === extra.id) ? <div key={extra.id} className="mt-1 p"><i className="fas fa-plus text-primary fa-1x" /> {extra.title}</div> : null
                    )}
                    {state.partner && <div className="mt-1 p text-danger">PS Kraftwagen Partner Rabatt ({new Intl.NumberFormat('de-DE').format(state.partner.discount_in_percent)}%)</div>}
                  </div>
                  <div className="col-2 text-end">
                    <span className="fw-bold p text-secondary">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(selectedPackage.price)}</span>
                    {selectedPackage.extras.items.map(extra => 
                      selectedExtras.find(selEx => selEx === extra.id) ? <div key={extra.id} className="mt-1 p"><span className="fw-bold p text-secondary">{extra.estimatedPrice ? <span>ab</span> : null} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(extra.price)}</span></div> : null
                    )}
                    {state.partner && <span className="mt-1 p d-block fw-bold p text-danger">- {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(calculatePrice(selectedPackage.id, false)*state.partner.discount_in_percent/100)}</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-end">
                    <hr />
                    <span className="fw-bold h1 text-secondary">{isPriceEstimated(selectedPackage.id) ? <span>ab</span> : <span>für</span>} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(calculatePrice(selectedPackage.id))}</span>
                    <span className="d-block small text-muted">Alle Preise verstehen sich inkl. der gesetzlich geltenden MwSt.</span>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12 col-md-6">
                    <input type="text" className="form-control" placeholder="Vorname" value={formFirstname} onChange={(e) => setFormFirstname(e.target.value)} required />
                  </div>
                  <div className="col-12 col-md-6 mt-3 mt-md-0">
                    <input type="text" className="form-control" placeholder="Nachname" value={formLastname} onChange={(e) => setFormLastname(e.target.value)} required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <input type="email" className="form-control mt-3" placeholder="E-Mail Adresse" value={formMail} onChange={(e) => setFormMail(e.target.value)} required />
                  </div>
                  <div className="col-6">
                    <input type="tel" className="form-control mt-3" placeholder="Telefon" value={formPhone} onChange={(e) => setFormPhone(e.target.value)} required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <input type="date" className={`${!isSafari() ? 'form-control' : ''} mt-3`} min={new Date().toISOString().split('T').shift()} value={formDate} onChange={(e) => setFormDate(e.target.value)} required />
                  </div>
                  <div className="col-6">
                    <input type="time" className={`${!isSafari() ? 'form-control' : ''} mt-3`}  value={formTime} onChange={(e) => setFormTime(e.target.value)} required />
                  </div>
                </div>
                {selectedExtras.find(key => ceramicIds.includes(key)) ?
                <div className="row">
                  <div className="col-12 text-start mt-3 border rounded border-success p-3">
                    <span className="fw-bold text-success d-block">Hinweis zur Keramikversiegelung</span>
                    <span className="small">Bei Keramikversiegelungen muss mit einem Leistungsaufwand von mindestens 3 Werktagen oder länger gerechnet werden.</span>
                  </div>
                </div> : null}
                {(new Date(formDate).getDay() === 6) ?
                <div className="row">
                  <div className="col-12 text-start mt-3 border rounded border-success p-3">
                    <span className="fw-bold text-success d-block">Hinweis für den Samstag</span>
                    <span className="small">Samstags ist die Aufbereitung geschlossen. Fahrzeuge können jedoch abgestellt werden.</span>
                  </div>
                </div> : null}
                {showOpeningHours ?
                <div className="row">
                  <div className="col-12 text-start mt-3 border rounded border-danger p-3">
                    <span className="fw-bold text-danger d-block">Ihr Terminwunsch liegt leider außerhalb unserer Öffnungszeiten</span>
                    <span className="small">Montag bis Freitag 08:00 - 18:00 Uhr <br/>Samstag 09:00 bis 13:00 Uhr</span>
                  </div>
                </div> : null}
                <div className="row mt-4">
                  <div className="col-12 bg-info text-center rounded p-3">
                    <p className="text-secondary mb-0">Für telefonische Terminanfragen bitte die Paket-Referenz angeben:</p>
                    <div className="h3 mt-2">Paket Nr. {packageNumber()}</div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-2 col-lg-1 text-end">
                    <input type="checkbox" className="form-check-input" />
                  </div>
                  <div className="col-10 col-lg-11 ps-0">
                    <p className="text-secondary">Tierhaare werden nach Stundenaufwand berechnet (50,00 &euro;/h).</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 col-lg-1 text-end">
                    <input type="checkbox" className="form-check-input" required />
                  </div>
                  <div className="col-10 col-lg-11 ps-0">
                    <p className="text-secondary">Bei starker Verschmutzung wie: Baustellenfahrzeuge oder ähnliches wird nach Leistungsaufwand berechnet.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 col-lg-1 text-end">
                    <input type="checkbox" className="form-check-input" required />
                  </div>
                  <div className="col-10 col-lg-11 ps-0">
                    <p className="text-secondary">Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer <a href="/datenschutz" target="_blank">Datenschutzerklärung</a></p>
                  </div>
                </div>
                {isPriceEstimated(selectedPackage.id) ?
                <div className="row">
                  <div className="col-2 col-lg-1 text-end">
                    <input type="checkbox" className="form-check-input" required />
                  </div>
                  <div className="col-10 col-lg-11 ps-0">
                    <p className="text-secondary"><span className="fw-bold">Hinweis:</span> Dieser Preis ist kalkuliert auf der Basis eines Fahrzeuges mit normalem Verschmutzungsgrad und Lackverschleiß. Bei außerordendlicher Lackabnutzung kann dieser Preis abweichen.</p>
                  </div>
                </div> : null}
                <div className="row mt-3">
                  <div className="col-12 text-end">
                    <button type="submit" className="mt-4 btn btn-secondary btn-lg px-5 border-white">
                      <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Terminanfrage absenden</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </> : null}

    </React.Fragment> : null}

    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

    <Footer />
  </>
}

export default Aufbereitung;

import React, {useReducer} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logger from 'use-reducer-logger';

import Ankauf from './pages/Ankauf';
import AnkaufBewertungDanke from './pages/Ankauf/BewertungDanke';
import Aufbereitung from './pages/Aufbereitung';
import Datenschutz from './pages/Datenschutz';
import Home from './pages/Home';
import Impressum from './pages/Impressum';
import Mietfahrzeuge from './pages/Mietfahrzeuge';
import PrimeModal from './components/PrimeModal';
import Service from './pages/Service';
import StoreContext, { initialState, reducer } from './storeContext';
import SyncVehicles from './components/SyncVehicles';
import Team from './pages/Team';
import Verkauf from './pages/Verkauf';
import VerkaufDetail from './pages/VerkaufDetail';

const AppRoutes = () => {
  const [state, dispatch] = useReducer(process.env.NODE_ENV === 'development' ? logger(reducer) : reducer, initialState);
  
  return <StoreContext.Provider value={{state, dispatch}}>
    <PrimeModal />
    <SyncVehicles />
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/ankauf" element={<Ankauf />} />
        <Route path="/ankauf/bewertung/danke" element={<AnkaufBewertungDanke />} />
        <Route path="/aufbereitung" element={<Aufbereitung />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/mietfahrzeuge" element={<Mietfahrzeuge />} />
        <Route path="/service" element={<Service />} />
        <Route path="/team" element={<Team />} />
        <Route path="/verkauf/:id/:title" element={<VerkaufDetail />} />
        <Route path="/verkauf" element={<Verkauf />} />
        <Route element={<Home />} />
      </Routes>
    </Router>
  </StoreContext.Provider>;
}

export default AppRoutes;
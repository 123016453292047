import {useEffect} from 'react';

import ContactBar from '../components/ContactBar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import sebastianMerkelAmArbeitsplatz from '../assets/images/sebastian-merkel-am-arbeitsplatz.jpg';
import portraitSebastianMerkel from '../assets/images/portrait-sebastian-merkel.jpg';
import psKraftwagenGarantieSiegel from '../assets/images/pskraftwagen-garantie-siegel.png';
import autoVerkaufLieferung from '../assets/images/begutachtung-verkauf-fahrzeug.jpg';
import autoVerkauf from '../assets/images/auto-verkauf.jpg';
import partner from '../assets/images/partner.png';
import Quote from '../components/Quote';
import StatisticBar from '../components/StatisticBar';

const Service = () => {
  
  useEffect(() => {
    const pageTitle = 'PS Kraftwagen GmbH | Service';

    document.title = pageTitle;

    window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)]);
    window._paq.push(['setDocumentTitle', pageTitle]);
    window._paq.push(['trackPageView']);
  });

  return <>
    <Header />
    
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 order-last order-lg-first">
            <h1 className="mt-5 text-uppercase text-secondary fw-bolder">Kraftwagen Service</h1>
            <p className="mt-3">Service im Gebrauchtwagenhandel bedeutet für uns "mehr" zu leisten, verfügbar zu sein und dem Kunden genau die Leistungen rundum seinen Gebrauchtwagen zu bieten die er auch tatsächlich braucht.</p>
          </div>
          <div className="col-12 col-lg-7 order-first order-lg-last">
            <img src={autoVerkauf} alt="Wir sind das PS Kraftwagen Team" className="img-fluid rounded shadow" />
          </div>
        </div>
      </div>
    </div>

    <Quote imgSrc={portraitSebastianMerkel} imgAlt="Portrait Sebastian Merkel" title="Der PS Kraftwagen Service" author="Sebastian Merkel" bgColor="secondary" color="white">
      Hinter einem zuverlässigen Auto steht auch ein zuverlässiger Händler
    </Quote>

    <div className="container-fluid py-md-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 order-last order-md-first">
            <div className="mt-4 h1 text-uppercase text-secondary fw-bolder">Zulassungsservice</div>
            <p>Wir kümmern uns auf Wunsch um die Zulassung oder Abmeldung Ihres Fahrzeuges und ersparen Ihnen somit den Gang zum Landratsamt - einfach &amp; unkompliziert.</p>
            <p className="text-uppercase h4 fw-bold text-secondary mt-4"><i className="fas fa-check-circle text-success me-2" />Umweltplakette inklusive</p>
            <p className="text-uppercase h4 fw-bold text-secondary mt-3"><i className="fas fa-check-circle text-success me-2" />Wunschkennzeichen inklusive</p>
            <p className="text-uppercase h4 fw-bold text-secondary mt-3"><i className="fas fa-check-circle text-success me-2" />preiswert &amp; unkompliziert</p>
          </div>
          <div className="col-12 col-md-5 order-first order-md-last">
            <img src={sebastianMerkelAmArbeitsplatz} alt="Verkauf eines Gebrauchtwagens" className="img-fluid rounded shadow" />
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid py-md-5 my-md-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mt-4 h1 text-uppercase text-secondary fw-bolder">Kosten pro Landkreis</div>
            <p className="text-uppercase h4 fw-bold text-secondary mt-3"><i className="fas fa-check-circle text-success me-2" />Umweltplakette <span className="fw-bolder">inklusive</span></p>
            <p className="text-uppercase h4 fw-bold text-secondary mt-4"><i className="fas fa-check-circle text-success me-2" />Wunschkennzeichen <span className="fw-bolder">inklusive</span></p>
            <p className="text-uppercase h4 fw-bold text-secondary mt-3"><i className="fas fa-check-circle text-success me-2" />Abmeldung für {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(20)}</p>
          </div>
        </div>
        <div className="row mt-4 align-items-center">
          <div className="col-12 col-md-2 text-center fw-bold landkreis-goeppingen py-5">
            <span className="h4 fw-bold m-0 d-block">Göppingen</span>
            <span className="h4 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(145)}</span>
          </div>
          <div className="col-12 col-md-2 text-center fw-bold landkreis-esslingen py-5">
            <span className="h4 fw-bold m-0 d-block">Esslingen</span>
            <span className="h4 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(162)}</span>
          </div>
          <div className="col-12 col-md-2 text-center fw-bold landkreis-stuttgart py-5">
            <span className="h4 fw-bold m-0 d-block">Stuttgart</span>
            <span className="h4 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(173)}</span>
          </div>
          <div className="col-12 col-md-2 text-center fw-bold landkreis-ulm py-5">
            <span className="h4 fw-bold m-0 d-block">Ulm</span>
            <span className="h4 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(177)}</span>
          </div>
          <div className="col-12 col-md-2 text-center fw-bold landkreis-reutlingen py-5">
            <span className="h4 fw-bold m-0 d-block">Reutlingen</span>
            <span className="h4 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(184)}</span>
          </div>
          <div className="col-12 col-md-2 text-center fw-bold europa py-5 border-start">
            <span className="small fw-bolder m-0 d-block">Export 30 Tage - {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(249)} </span>
            <span className="small fw-bolder m-0 d-block">Kurzzeit mit Vers. - {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(149)} </span>
          </div>
        </div>
        <p className="text-end small mt-3">Alle Preise inkl. der gesetzlich geltenden Mehrwertsteuer.</p>
      </div>
    </div>

    <div className="container-fluid py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-7 ps-lg-5 order-last order-md-last">
            <div className="mt-4 h1 text-uppercase text-secondary fw-bolder">Gebrauchtwagengarantie</div>
            <p>PS Kraftwagen bietet Ihnen preiswerte Garantie-Angebote passend zu Ihrem Gebrauchtwagen.</p>
            <div className="row mt-4">
              <div className="col-6 text-white px-3 py-2 fw-bold">&nbsp;</div>
              <div className="col-3 bg-secondary text-white px-3 py-2 fw-bold">12 Monate</div>
              <div className="col-3 bg-secondary text-white px-3 py-2 fw-bold">24 Monate</div>
            </div>
            <div className="row mt-4">
              <div className="col-6 bg-primary text-white px-3 py-2 fw-bold">bis 85 KW</div>
              <div className="col-3 bg-secondary text-white px-3 py-2 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(349)}</div>
              <div className="col-3 bg-secondary text-white px-3 py-2 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(499)}</div>
            </div>
            <div className="row mt-1">
              <div className="col-6 bg-primary text-white px-3 py-2 fw-bold">bis 146 KW</div>
              <div className="col-3 bg-secondary text-white px-3 py-2 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(399)}</div>
              <div className="col-3 bg-secondary text-white px-3 py-2 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(599)}</div>
            </div>
            <div className="row mt-1">
              <div className="col-6 bg-primary text-white px-3 py-2 fw-bold">bis 180 KW</div>
              <div className="col-3 bg-secondary text-white px-3 py-2 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(649)}</div>
              <div className="col-3 bg-secondary text-white px-3 py-2 fw-bold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3}).format(949)}</div>
            </div>
          </div>
          <div className="col-7 col-md-3 offset-md-2 order-first order-md-last">
            <img src={psKraftwagenGarantieSiegel} alt="PS Kraftwagen Garantie Siegel" className="img-fluid mt-md-4" />
          </div>
        </div>
      </div>
    </div>

    {/* <div className="container-fluid py-5 my-md-5 bg-light shadow">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-7 ps-lg-5 order-last order-md-first">
            <div className="mt-4 h1 text-uppercase text-secondary fw-bolder">Kraftwagen Rückgaberecht</div>
            <p className="fw-bold">Um Ihnen mehr Sicherheit und ein gutes Gefühl zu bieten nehmen wir jedes Fahrzeug 30 Tage nach Übergabe zurück.</p>
            <p>30 Tage ab Empfangsdatum – (nur bei triftigem Grund) – (Mehrkosten entstehen durch: gefahrene KM, Mehrhalter und Verschleißpauschale je nach Modell.)</p>
          </div>
          <div className="col-12 col-md-3 offset-md-2 text-center order-first order-md-last">
            <i className="fas fa-history fa-7x text-secondary" />
          </div>
        </div>
      </div>
    </div> */}

    <div className="container-fluid py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-7 ps-lg-5 order-last order-md-first">
            <div className="mt-4 h1 text-uppercase text-secondary fw-bolder">Kraftwagen Lieferung</div>
            <p>
              Kostenfreie Lieferung bis 100 km – PS Kraftwagen liefert Ihnen Ihren neuen Gebrauchtwagen binnen 7 Werktagen bis vor Ihre Haustüre.<br/>
              Alle Transporte werden versichert und von uns selbst auf dem Kraftwagen-Transporter vollzogen.<br/>
              Sie bezahlen Ihr Fahrzeug erst bei Erhalt vor Ihrer Haustüre bar oder via Echtzeit-Überweisung.
            </p>
            <p className="fw-bold">Kunden lieben unseren Service - überzeugen Sie sich selbst:</p>
            <div className="d-none d-lg-block">
              <a href="https://www.google.com/search?q=ps+kraftwagen+gmbh&rlz=1C5CHFA_enDE849DE849&sxsrf=ALeKk03hWLf3xMhYZ9CBXF-cJDUD4HhptA%3A1611050870002&ei=da8GYKXXPI3zkgXv65eoDQ&oq=pskraftwagen+gm&gs_lcp=CgZwc3ktYWIQAxgAMgYIABAWEB46BAgjECc6AggmULZDWNpGYMlSaABwAHgAgAFciAG5ApIBATSYAQCgAQGqAQdnd3Mtd2l6wAEB&sclient=psy-ab#lrd=0x4799a3c200d37da7:0x951410fd6e1372b2,1,," type="button" className="mt-4 btn btn-secondary btn-lg px-5 border-white">
                <i className="fas fa-star text-warning me-1" /> <span className="fw-bold">Bewertungen auf Google ansehen</span>
              </a>
            </div>
            <a href="https://www.google.com/search?q=ps+kraftwagen+gmbh&rlz=1C5CHFA_enDE849DE849&sxsrf=ALeKk03hWLf3xMhYZ9CBXF-cJDUD4HhptA%3A1611050870002&ei=da8GYKXXPI3zkgXv65eoDQ&oq=pskraftwagen+gm&gs_lcp=CgZwc3ktYWIQAxgAMgYIABAWEB46BAgjECc6AggmULZDWNpGYMlSaABwAHgAgAFciAG5ApIBATSYAQCgAQGqAQdnd3Mtd2l6wAEB&sclient=psy-ab#lrd=0x4799a3c200d37da7:0x951410fd6e1372b2,1,," type="button" className="d-lg-none mt-4 btn btn-secondary px-5 border-white">
              <i className="fas fa-star text-warning me-1" /> <span className="fw-bold">Bewertungen auf Google ansehen</span>
            </a>
          </div>
          <div className="col-12 col-md-5 order-first order-md-last">
            <img src={autoVerkaufLieferung} alt="Anlieferung eines Gebrauchtwagens" className="img-fluid rounded shadow" />
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid my-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mt-4 h1 text-uppercase text-secondary fw-bolder">Kraftwagen Partner</div>
            <p>
              Hinter einem starken Unternehmen stehen starke Partner. Wir sind Spezialisten im Gebrauchtwagenhandel und arbeiten deshalb mit spezialisierten 
              Partnern in allen Bereichen.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <img src={partner} alt="Unsere Partner" className="img-fluid mt-3" />
          </div>
        </div>
      </div>
    </div>

    <StatisticBar theme="secondary" />

    <ContactBar variant="light" />

    <Footer />
  </>
}

export default Service;

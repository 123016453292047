import {useEffect} from 'react';
import {Link} from 'react-router-dom';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

import winWinSituationen from '../../assets/images/wir-schaffen-win-win-situationen.jpg';
import sebastianMerkelAmArbeitsplatz from '../../assets/images/sebastian-merkel-am-arbeitsplatz.jpg';

const BewertungDanke = () => {

  useEffect(() => {
    const pageTitle = 'PS Kraftwagen GmbH | Ankauf | Bewertung | Danke';

    document.title = pageTitle;

    window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)]);
    window._paq.push(['setDocumentTitle', pageTitle]);
    window._paq.push(['trackPageView']);
  });

  return <>
    <Header />
    
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 order-last order-lg-first">
            <div className="text-center mt-4 mt-lg-0">
              <i className="fas fa-check-circle fa-4x fa-fw text-success"></i>
            </div>
            <h1 className="mt-3 text-uppercase text-secondary fw-bolder">Vielen Dank für Ihre Anfrage</h1>
            <div className="row mt-4">
              <div className="col-12 text-center">
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="">Wir werden uns umgehend mit Ihnen in Verbindung setzen.</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7 order-first order-lg-last">
            <img src={sebastianMerkelAmArbeitsplatz} alt="Verkauf eines Gebrauchtwagens" className="img-fluid rounded shadow" />
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid py-5 bg-secondary bg-gradient">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 text-center">
            <div className="text-white">
              <i className="fas fa-thumbs-up fa-6x fa-fw" />
              <div className="h4 fw-bold mt-3">Top Service</div>
              <p>Lassen Sie sich von unserem Service im Ankauf überzeugen</p>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center">
            <div className="text-white">
              <i className="fas fa-check-circle fa-6x fa-fw" />
              <div className="h4 fw-bold mt-3">Schnelle Abwicklung</div>
              <p>Unkomplizierte &amp; schnelle Abwicklung mit direkter Bezahlung</p>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center">
            <div className="text-white">
              <i className="fas fa-euro-sign fa-6x fa-fw" />
              <div className="h4 fw-bold mt-3">Faires Angebot</div>
              <p>Wir bieten Ihnen einen fairen Preis für Ihren Gebrauchtwagen</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <img src={winWinSituationen} alt="Verkauf eines Gebrauchtwagens" className="img-fluid rounded shadow" />
          </div>
          <div className="col-12 col-lg-6 ps-lg-5">
            <div className="mt-4 h1 text-uppercase text-secondary fw-bolder">Wir schaffen Win-Win Situationen</div>
            <p>Sebastian Merkel</p>
            <div className="d-none d-lg-block">
              <Link to="/team" type="button" className="mt-4 btn btn-secondary btn-lg px-5 border-white">
                <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Team kennenlernen</span>
              </Link>
            </div>
            <Link to="/team" type="button" className="d-lg-none mt-4 btn btn-secondary px-5 border-white">
              <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Team kennenlernen</span>
            </Link>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </>
}

export default BewertungDanke;

import React from 'react';

const initialState = {
  partner: null,
  preparation: {
    car: {
      packages: [{
        id: 'car_individual',
        title: 'Individual',
        backgroundColor: '#f8f9fa',
        isRecommended: false,
        workingTime: '',
        extras: {
          items: [
            {id: 'car_individual_1', title: 'Gummipflege inkl. Reifenglanz', price: 9},
            {id: 'car_individual_2', title: 'Motorwäsche', price: 29},
            {id: 'car_individual_3', title: 'Endrohre polieren', price: 39},
            {id: 'car_individual_4', title: 'B-Säule polieren', price: 49},
            {id: 'car_individual_5', title: 'Shampoonierte Handaußenwäsche inkl. Felgenreinigung', price: 59},
            {id: 'car_individual_6', title: 'Verdeck Imprägnierung', price: 59, dependsOn: 'car_individual_5'},
            {id: 'car_individual_7', title: 'Klavierlack polieren (Innenraum)', price: 69},
            {id: 'car_individual_8', title: 'Scheibenversiegelung', price: 69},
            {id: 'car_individual_9', title: 'Dachhimmelreinigung', price: 79},
            {id: 'car_individual_10', title: 'Lederreinigung inkl. Lederpflege', price: 79},
            {id: 'car_individual_11', title: 'Wachsversiegelung', price: 99, dependsOn: 'car_individual_5'},
            {id: 'car_individual_12', title: '3-Fach Politur inkl. Antihologramm Versiegelung', price: 229, dependsOn: 'car_individual_5'},
            {id: 'car_individual_13', title: 'Keramikversiegelung (Informationen zum Vorgang auf Anfrage)', price: 649, estimatedPrice: true, dependsOn: 'car_individual_5'},
          ]
        },
        price: 0.00
      }, {
        id: 'car_silber',
        title: 'Silber',
        backgroundColor: '#c4cace',
        isRecommended: false,
        workingTime: '3 Stunden',
        categories: [
          {title: 'Außen', items: ['Heißwasser Außenwäsche', 'Shampoonierte Handwäsche', 'Radkästen abdampfen', 'Türfalzen & Einstiegleisten reinigen', 'Felgenreinigung']}, 
          {title: 'Innen', items: ['Absaugen kompletter Innenraum', 'Reinigung aller Armaturen', 'Scheibenreinigung', 'Intensive Pedalerie Reinigung', 'Hyla Luftreinigung']}
        ],
        extras: {
          title: 'Zusätzliche Optionen',
          collapsed: true,
          items: [
            {id: 'car_silber_1', title: 'Reifenglanz', price: 0},
            {id: 'car_silber_2', title: 'Endrohre polieren', price: 19},
            {id: 'car_silber_3', title: 'Motorwäsche', price: 19},
            {id: 'car_silber_4', title: 'B-Säule polieren', price: 49},
            {id: 'car_silber_5', title: 'Scheibenversiegelung', price: 49},
            {id: 'car_silber_6', title: 'Verdeck-Imprägnierung', price: 59},
            {id: 'car_silber_7', title: 'Dachhimmelreinigung', price: 79},
            {id: 'car_silber_8', title: 'Lederreinigung inkl. Lederpflege', price: 79},
            {id: 'car_silber_9', title: 'Wachsversiegelung', price: 99},
            {id: 'car_silber_10', title: '3-Fach Politur', price: 229},
            {id: 'car_silber_11', title: 'Keramikversiegelung (Informationen zum Vorgang auf Anfrage)', price: 649, estimatedPrice: true},
          ]
        },
        price: 149.00
      }, {
        id: 'car_gold',
        title: 'Gold',
        backgroundColor: '#d4af37',
        isRecommended: true,
        workingTime: '6 Stunden',
        categories: [
          {title: 'Außen', items: ['Heißwasser Außenwäsche', 'Shampoonierte Handwäsche', 'Radkästen abdampfen', 'Türfalzen & Einstiegleisten reinigen', 'Felgenreinigung', 'Flugrostentfernung', 'Auspuffrohre polieren', 'Hochglanzpolitur']},
          {title: 'Innen', items: ['Absaugen kompletter Innenraum', 'Reinigung aller Armaturen', 'Scheibenreinigung', 'Intensive Pedalerie Reinigung', 'Hyla Luftreinigung', 'Polster/Leder Reinigung', 'Intensive Lenkradreinigung', 'Türdichtung/Gummipflege', 'Innenreinigung mit Tornado']}
        ],
        extras: {
          title: 'Zusätzliche Optionen',
          collapsed: true,
          items: [
            {id: 'car_gold_1', title: 'Reifenglanz', price: 0},
            {id: 'car_gold_2', title: 'Motorwäsche', price: 19},
            {id: 'car_gold_5', title: 'B-Säule polieren', price: 49},
            {id: 'car_gold_4', title: 'Lederpflege', price: 39},
            {id: 'car_gold_3', title: 'Scheibenversiegelung', price: 49},
            {id: 'car_gold_6', title: 'Verdeck-Imprägnierung', price: 59},
            {id: 'car_gold_7', title: 'Dachhimmelreinigung', price: 79},
            {id: 'car_gold_8', title: 'Wachsversiegelung', price: 99},
            {id: 'car_gold_9', title: 'Keramikversiegelung (Informationen zum Vorgang auf Anfrage)', price: 479, estimatedPrice: true},
          ],
        },
        price: 279.00
      }, {
        id: 'car_platin',
        title: 'Platin',
        backgroundColor: '#7f7679',
        isRecommended: false,
        workingTime: '10 Stunden',
        categories: [
          {title: 'Außen', items: ['Heißwasser Außenwäsche', 'Shampoonierte Handwäsche', 'Radkästen abdampfen', 'Türfalzen & Einstiegleisten reinigen', 'Felgenreinigung', 'Flugrostentfernung', 'Auspuffrohre polieren', 'Hochglanzpolitur', 'Lack kneten', 'Schleifpolitur', 'Antihologrammpolitur', 'B-Säulenpolitur', 'Kunstoffpflege Außenteile']},
          {title: 'Innen', items: ['Absaugen kompletter Innenraum', 'Reinigung aller Armaturen', 'Scheibenreinigung', 'Intensive Pedalerie Reinigung', 'Hyla Luftreinigung', 'Polster-Lederreinigung', 'Intensive Lenkradreinigung', 'Türdichtung/Gummipflege', 'Innenreinigung mit Tornado']}
        ],
        extras: {
          title: 'Zusätzliche Optionen',
          collapsed: true,
          items: [
            {id: 'car_platin_1', title: 'Reifenglanz', price: 0},
            {id: 'car_platin_2', title: 'Motorwäsche', price: 19},
            {id: 'car_platin_3', title: 'Scheibenversiegelung', price: 49},
            {id: 'car_platin_4', title: 'Verdeck-Imprägnierung', price: 59},
            {id: 'car_platin_5', title: 'Dachhimmelreinigung', price: 79},
            {id: 'car_platin_6', title: 'Wachsversiegelung', price: 79},
            {id: 'car_platin_7', title: 'Keramikversiegelung (Informationen zum Vorgang auf Anfrage)', price: 249, estimatedPrice: true},
          ]
        },
        price: 399.00
      }],
    },
    suv: {
      packages: [{
        id: 'suv_individual',
        title: 'Individual',
        backgroundColor: '#f8f9fa',
        isRecommended: false,
        workingTime: '',
        extras: {
          items: [
            {id: 'suv_individual_1', title: 'Gummipflege inkl. Reifenglanz', price: 9},
            {id: 'suv_individual_3', title: 'Endrohre polieren', price: 39},
            {id: 'suv_individual_2', title: 'Motorwäsche', price: 39},
            {id: 'suv_individual_4', title: 'B-Säule polieren', price: 49},
            {id: 'suv_individual_5', title: 'Scheibenversiegelung', price: 59},
            {id: 'suv_individual_7', title: 'Klavierlack polieren (Innenraum)', price: 69, estimatedPrice: true},
            {id: 'suv_individual_8', title: 'Dachhimmelreinigung', price: 79},
            {id: 'suv_individual_9', title: 'Lederreinigung inkl. Lederpflege', price: 79},
            {id: 'suv_individual_10', title: 'Shampoonierte Handaußenwäsche inkl. Felgenreinigung', price: 89},
            {id: 'suv_individual_11', title: 'Wachsversiegelung', price: 119, dependsOn: 'suv_individual_10'},
            {id: 'suv_individual_12', title: '3-Fach Politur inkl. Antihologramm Versiegelung', price: 279, dependsOn: 'suv_individual_10'},
            {id: 'suv_individual_13', title: 'Keramikversiegelung (Informationen zum Vorgang auf Anfrage) ab', price: 729, estimatedPrice: true, dependsOn: 'suv_individual_10'},
          ]
        },
        price: 0.00
      }, {
        id: 'suv_silber',
        title: 'Silber',
        backgroundColor: '#c4cace',
        isRecommended: false,
        workingTime: '4 Stunden',
        categories: [
          {title: 'Außen', items: ['Heißwasser Außenwäsche', 'Shampoonierte Handwäsche', 'Radkästen abdampfen', 'Türfalzen & Einstiegleisten reinigen', 'Felgenreinigung']}, 
          {title: 'Innen', items: ['Absaugen kompletter Innenraum', 'Reinigung aller Armaturen', 'Scheibenreinigung', 'Intensive Pedalerie Reinigung', 'Hyla Luftreinigung']}
        ],
        extras: {
          title: 'Zusätzliche Optionen',
          collapsed: true,
          items: [            
            {id: 'suv_silber_1', title: 'Reifenglanz', price: 0},
            {id: 'suv_silber_2', title: 'Endrohre polieren', price: 19},
            {id: 'suv_silber_3', title: 'Motorwäsche', price: 19},
            {id: 'suv_silber_4', title: 'B-Säule polieren', price: 49},
            {id: 'suv_silber_5', title: 'Scheibenversiegelung', price: 49},
            {id: 'suv_silber_6', title: 'Lederreinigung inkl. Lederpflege', price: 79},
            {id: 'suv_silber_7', title: 'Dachhimmelreinigung', price: 99},
            {id: 'suv_silber_8', title: 'Wachsversiegelung', price: 139},
            {id: 'suv_silber_9', title: '3-Fach Politur', price: 279},
            {id: 'suv_silber_10', title: 'Keramikversiegelung (Informationen zum Vorgang auf Anfrage)', price: 729, estimatedPrice: true},
          ]
        },
        price: 239
      }, {
        id: 'suv_gold',
        title: 'Gold',
        backgroundColor: '#d4af37',
        isRecommended: true,
        workingTime: '7,5 Stunden',
        categories: [
          {title: 'Außen', items: ['Heißwasser Außenwäsche', 'Shampoonierte Handwäsche', 'Radkästen abdampfen', 'Türfalzen & Einstiegleisten reinigen', 'Felgenreinigung', 'Flugrostentfernung', 'Auspuffrohre polieren', 'Hochglanzpolitur']},
          {title: 'Innen', items: ['Absaugen kompletter Innenraum', 'Reinigung aller Armaturen', 'Scheibenreinigung', 'Intensive Pedalerie Reinigung', 'Hyla Luftreinigung', 'Polster/Leder Reinigung', 'Intensive Lenkradreinigung', 'Türdichtung/Gummipflege', 'Innenreinigung mit Tornado']}
        ],
        extras: {
          title: 'Zusätzliche Optionen',
          collapsed: true,
          items: [
            {id: 'suv_gold_1', title: 'Reifenglanz', price: 0},
            {id: 'suv_gold_2', title: 'Motorwäsche', price: 19},
            {id: 'suv_gold_3', title: 'B-Säule polieren', price: 49},
            {id: 'suv_gold_4', title: 'Lederpflege', price: 39},
            {id: 'suv_gold_5', title: 'Scheibenversiegelung', price: 49},
            {id: 'suv_gold_6', title: 'Dachhimmelreinigung', price: 99},
            {id: 'suv_gold_7', title: 'Wachsversiegelung', price: 139},
            {id: 'suv_gold_8', title: 'Keramikversiegelung (Informationen zum Vorgang auf Anfrage)', price: 559, estimatedPrice: true},
          ],
        },
        price: 349
      }, {
        id: 'suv_platin',
        title: 'Platin',
        backgroundColor: '#7f7679',
        isRecommended: false,
        workingTime: '13 Stunden',
        categories: [
          {title: 'Außen', items: ['Heißwasser Außenwäsche', 'Shampoonierte Handwäsche', 'Radkästen abdampfen', 'Türfalzen & Einstiegleisten reinigen', 'Felgenreinigung', 'Flugrostentfernung', 'Auspuffrohre polieren', 'Hochglanzpolitur', 'Lack kneten', 'Schleifpolitur', 'Antihologrammpolitur', 'B-Säulenpolitur', 'Kunstoffpflege Außenteile']},
          {title: 'Innen', items: ['Absaugen kompletter Innenraum', 'Reinigung aller Armaturen', 'Scheibenreinigung', 'Intensive Pedalerie Reinigung', 'Hyla Luftreinigung', 'Polster-Lederreinigung', 'Intensive Lenkradreinigung', 'Türdichtung/Gummipflege', 'Innenreinigung mit Tornado']}
        ],
        extras: {
          title: 'Zusätzliche Optionen',
          collapsed: true,
          items: [
            {id: 'suv_platin_1', title: 'Reifenglanz', price: 0},
            {id: 'suv_platin_2', title: 'Motorwäsche', price: 19},
            {id: 'suv_platin_3', title: 'Scheibenversiegelung', price: 49},
            {id: 'suv_platin_4', title: 'Dachhimmelreinigung', price: 99},
            {id: 'suv_platin_5', title: 'Wachsversiegelung', price: 99},
            {id: 'suv_platin_6', title: 'Keramikversiegelung (Informationen zum Vorgang auf Anfrage)', price: 329, estimatedPrice: true},
          ]
        },
        price: 499
      }],
    }
  },
  vehicles: [],
  vehiclesFetched: false,
};

function reducer(store, action) {
  switch (action.type) {
    case 'UPDATE_STORE':
      return {...store, ...action.payload};
    default:
      throw new Error();
  }
}

const StoreContext = React.createContext();

export { StoreContext as default, initialState, reducer };
import {useEffect} from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';
// import Stock from '../components/Stock';
// import StoreContext from '../storeContext';
// import VehicleHeader from '../components/VehicleHeader';

const Verkauf = () => {
  // const {state} = useContext(StoreContext);
  // const vehicleOfTheWeek = state.vehicles[Math.floor(Math.random() * state.vehicles.length)];
  
  useEffect(() => {
    const pageTitle = 'PS Kraftwagen GmbH | Verkauf';

    const script = document.createElement("script");
    script.src = "https://widget.x.cloud.audaris.icu/widget.js?cid=2395&wid=63988ebc144b694c7b0cf71c";
    script.async = true;
    document.body.appendChild(script);

    document.title = pageTitle;

    window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)]);
    window._paq.push(['setDocumentTitle', pageTitle]);
    window._paq.push(['trackPageView']);
  });

  return <>
    <Header />

    <div className="container-fluid py-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div id="audaris-widget"></div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </>

}

//   return vehicleOfTheWeek ? <>
//     <Header />
    
//     <div className="modal fade" id="probefahrt-vereinbaren-modal" tabIndex="-1" aria-labelledby="probefahrt-vereinbaren-modal-label" aria-hidden="true">
//       <div className="modal-dialog">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h5 className="modal-title" id="probefahrt-vereinbaren-modal-label">Probefahrt vereinbaren</h5>
//             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//           </div>
//           <div className="modal-body">
//             Für eine Probefahrt nehmen Sie einfach Kontakt mit uns auf.
//             <a href="tel:+4971618085101" className="d-block text-center mt-4 border bg-light p-3">
//               <i className="fas fa-phone fa-1x pe-2" />
//               <span className="h4">07161 808 51 01</span>
//             </a>
//             <a href="https://api.whatsapp.com/send/?phone=+4915206348058&text&app_absent=0" className="d-block text-center mt-2 border bg-light p-3">
//               <i className="fab fa-whatsapp fa-1x pe-2" />
//               <span className="h4">01520 / 6348058</span>
//             </a>
//             <a href="mailto:info@pskraftwagen.de" className="d-block text-center mt-2 border bg-light p-3">
//               <i className="fas fa-envelope fa-1x pe-2" />
//               <span className="h4">info@pskraftwagen.de</span>
//             </a>
//           </div>
//           <div className="modal-footer">
//             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
//           </div>
//         </div>
//       </div>
//     </div>
    
//     <div className="container-fluid py-lg-5">
//       <div className="container">
//         <div className="row">
//           <div className="col-6">
//             <h1 className="mt-4 text-uppercase text-secondary fw-bolder">Kraftwagen der Woche</h1>
//           </div>
//         </div>
//       </div>
//     </div>

//     <VehicleHeader vehicle={vehicleOfTheWeek} showDetail={true} showWhatsApp={false} showTestDrive={true} />

//     <Stock />

//     <Footer />
//   </> : <>
//     <Header />   

//     <div className="container-fluid py-5">
//       <div className="container">
//         <div className="row align-items-center">
//           <div className="col-12 col-lg-5 order-last order-lg-first">
//             <h1 className="mt-5 text-uppercase text-secondary fw-bolder">Keine Fahrzeuge im Bestand</h1>
//             <p className="mt-3">
//               Wir haben zur Zeit keine Fahrzeuge im Bestand. 
//               Schauen Sie gerne später noch einmal vorbei um ein passendes Angebot zu finden.
//             </p>
//           </div>
//           <div className="col-12 col-lg-7 order-first order-lg-last text-center">
//             <i className="fas fa-car fa-7x"></i>
//           </div>
//         </div>
//       </div>
//     </div>
  
//     <Footer />
//   </>;

export default Verkauf;
import React, {useEffect, useState} from 'react';

import ContactBar from '../components/ContactBar';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Mietfahrzeuge = ({match}) => {
  const [activeImageMegane, setActiveImageMegane] = useState('mietwagen-megane-1.jpg')
  const [activeImageScenic, setActiveImageScenic] = useState('mietwagen-scenic-1.jpg')
  const vehicles = [{
    id: 'megane',
    title: 'Renault Megane',
    images: [
      'mietwagen-megane-2.jpg',
      'mietwagen-megane-3.jpg',
      'mietwagen-megane-4.jpg',
      'mietwagen-megane-5.jpg',
      'mietwagen-megane-6.jpg',
      'mietwagen-megane-7.jpg',
      'mietwagen-megane-8.jpg',
      'mietwagen-megane-9.jpg',
      'mietwagen-megane-10.jpg',
    ]
  }, {
    id: 'scenic',
    title: 'Renault Scenic',
    images: [
      'mietwagen-scenic-2.jpg',
      'mietwagen-scenic-3.jpg',
      'mietwagen-scenic-4.jpg',
      'mietwagen-scenic-5.jpg',
      'mietwagen-scenic-6.jpg',
      'mietwagen-scenic-7.jpg',
      'mietwagen-scenic-8.jpg',
      'mietwagen-scenic-9.jpg',
      'mietwagen-scenic-10.jpg',
    ]
  }]

  useEffect(() => {
    const pageTitle = `PS Kraftwagen GmbH | Mietfahrzeuge`
  
    document.title = pageTitle

    window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)])
    window._paq.push(['setDocumentTitle', pageTitle])
    window._paq.push(['trackPageView'])
  })

  return <>
    <Header />
    
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="mt-5 text-uppercase text-secondary fw-bolder">Mietfahrzeuge</h1>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid mt-md-5 mb-3">
      <div className="container">
        <div className="row">
          {vehicles && vehicles.map(vehicle => <React.Fragment key={vehicle.id}>
            <div className="col-12 col-lg-6">
              <h2 className="mt-4 text-uppercase text-secondary fw-bolder">{vehicle.title}</h2>
              <div className="row">
                <div className="col-12">
                  {vehicle.id === 'megane' ? <img src={`/images/${activeImageMegane}`} alt={`${vehicle.title} ${activeImageMegane}`} className="img-fluid rounded" /> : null}
                  {vehicle.id === 'scenic' ? <img src={`/images/${activeImageScenic}`} alt={`${vehicle.title} ${activeImageScenic}`} className="img-fluid rounded" /> : null}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 text-center">
                  {vehicle.images.map(image => <img key={image} src={`/images/${image}`} alt={`${vehicle.title} ${vehicle.image}`} className="img-fluid rounded mt-1 me-1 cursor-pointer" width="90" onClick={vehicle.id === 'megane' ? () => setActiveImageMegane(image) : () => setActiveImageScenic(image)} />)}
                </div>
              </div>
            </div>
          </React.Fragment>)}
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-euro-sign me-2 text-secondary" /> Tagessatz {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(69.00)}
              <span className="fw-normal h5 ms-3 text-primary d-block d-lg-inline">(08:00 – 18:00 Uhr)</span>
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-sun me-2 text-secondary" />Wochenendsatz {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(199.00)} 
              <span className="fw-normal h5 ms-3 text-primary d-block d-lg-inline">(Freitag 08:00 – Montag 08:00 Uhr)</span>
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-road me-2 text-secondary" />Freikilometer 100
              <span className="fw-normal h5 text-primary">/Tag</span>
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-plus me-2 text-secondary" />Weitere Kilometer {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(0.20)} 
              <span className="fw-normal h5 text-primary">/km</span>
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-gas-pump me-2 text-secondary" />Tankstand Voll
              <span className="fw-normal h5 ms-3 text-primary d-block d-lg-inline">(Voll abzugeben) Ohne Betankung {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(1.65)}/Liter</span>
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-birthday-cake me-2 text-secondary" />Mindestalter 18 Jahre
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-car-crash me-2 text-secondary" />Versicherung Vollkasko
              <span className="fw-normal h5 ms-3 text-primary d-block d-lg-inline">(Selbstbeteiligung {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(1500)} pro Schadenfall)</span>
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-suitcase me-2 text-secondary" />Auslandsfahrten nicht gestattet
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-cog me-2 text-secondary" />Ausstattung NAVI, Klimaautomatik, Parksensoren vorn und hinten, Schaltgetriebe
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-id-badge me-2 text-secondary" />Sonstiges Gültiger Führerschein und gültiger Personalausweis
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-handshake me-2 text-secondary" />Kaution {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(500)}
              <span className="fw-normal h5 ms-3 text-primary d-block d-lg-inline">zu hinterlegen (bar/EC) bei freier Anmietung</span>
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-smile me-2 text-secondary" />Für Kunden unserer Fahrzeugaufbereitung gilt folgender ermäßigter Tagessatz: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(49)}
              <span className="fw-normal h5 text-primary d-block d-lg-inline">(08:00 – 18:00 Uhr)</span>
            </div>
            <div className="mt-3 fw-normal h4">
              <i className="fas fa-clock me-2 text-secondary" />Anfragen rund um die Uhr
            </div>
          </div>
        </div>
      </div>
    </div>

    <ContactBar title="Kontakt" variant="light" />

    <Footer />
  </>
}

export default Mietfahrzeuge;
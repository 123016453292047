import {useContext} from 'react';
import {Link} from 'react-router-dom';

import generatePermalink from '../helpers/generatePermalink';
import StoreContext from '../storeContext';

const Stock = () => {
  const {state} = useContext(StoreContext);

  return state.vehicles ? <>
    
    <div className="container-fluid py-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mt-4 text-uppercase text-secondary fw-bolder">Aktuelle Kraftwagen</h2>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid">
      <div className="container">
        <div className="row">
          {state.vehicles.map(vehicle =>
            <div key={vehicle.id} className="col-12 col-md-4 col-lg-3 text-decoration-none">
              <img src={vehicle.images.XL[0].replace('$_18.JPG', '$_27.JPG')} alt={vehicle.title} className="img-fluid rounded-top" width="350" />
              <div className="p-3 shadow rounded">
                <div className="border-bottom border-primary" style={{minHeight: 60}}>
                  <h4 className="mt-2 text-uppercase text-primary fw-bold">{vehicle.title.length > 24 ? `${vehicle.title.slice(0,24)}...` : vehicle.title}</h4>
                </div>
                <div className="h3 mt-2 text-uppercase text-secondary fw-bolder">
                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(vehicle.price)}
                </div>
                <div className="mt-3 fw-normal">
                  <i className="fas fa-history me-2 text-secondary" /> Erstzulassung {vehicle.specifics.find(specific => specific.key === 'ad:first-registration').value.split('-').reverse().join('/')}
                </div>
                <div className="mt-1 fw-normal">
                  <i className="fas fa-road me-2 text-secondary" /> {new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(vehicle.mileage)} km
                </div>
                <div className="mt-1 fw-normal">
                  <i className="fas fa-gas-pump me-2 text-secondary" /> {vehicle.specifics.find(specific => specific.key === 'ad:fuel')?.value}
                </div>
                <div className="mt-1 fw-normal">
                  <i className="fas fa-tachometer-alt me-2 text-secondary" /> {vehicle.power} kW ({Math.round(vehicle.power*1.36)} PS)
                </div>
                <div className="mt-1 fw-normal">
                  <i className="fas fa-cog me-2 text-secondary" /> {vehicle.specifics.find(specific => specific.key === 'ad:gearbox')?.value}
                </div>
              </div>
              <div className="bg-secondary rounded-bottom mb-5 p-2">
                <Link to={`/verkauf/${vehicle.id}/${generatePermalink(vehicle.title)}`} className="text-center d-block">
                  <i className="fas fa-chevron-circle-right text-white me-2" /> <span className="fw-bold text-white">zum Angebot</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </> : null;
}

export default Stock;
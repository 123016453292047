import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import generatePermalink from '../helpers/generatePermalink';

const VehicleHeader = ({showDetail, showTestDrive, showWhatsApp, vehicle}) => {
  const [imageGalerie, setImageGalerie] = useState(null);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [activeImage, setActiveImage] = useState(null);

  useEffect(() => {
    async function imageGalerieMapper() {
      const mapper = [];
      let mapperCounter = 0;
      let loopCounter = 1;
      
      vehicle.images.S.forEach(image => {
        if (!mapper[mapperCounter]) {
          mapper[mapperCounter] = [];
        }
  
        if (loopCounter % 3 === 0) {
          mapper[mapperCounter].push(image);
          mapperCounter++;
        } else {
          mapper[mapperCounter].push(image);
        }
  
        loopCounter++;
      });

      setImageGalerie(mapper);
    }
    
    setActiveImage(vehicle.images.XL[0]);
    imageGalerieMapper(vehicle);

  }, [vehicle]);

  

  return vehicle ? <>
    <div className="container-fluid bg-light py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12 border-bottom border-primary">
                <h2 className="mt-3 mt-lg-0 text-uppercase text-primary fw-normal">{vehicle.title}</h2>
              </div>
            </div>
            <div className="row align-items-end">
              <div className="col-12 col-md-6">
                <div className="h2 mt-2 mt-lg-4 text-uppercase text-secondary fw-bolder">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(vehicle.price)}</div>
              </div>
              <div className="col-12 col-md-6 text-md-end">
                <div className="h4 mt-lg-4 text-uppercase text-info fw-bold">mtl. circa {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(vehicle.price*0.015)}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mt-3 fw-normal h4">
                  <i className="fas fa-history me-2 text-secondary" /> Erstzulassung {vehicle.specifics.find(specific => specific.key === 'ad:first-registration').value.split('-').reverse().join('/')}
                </div>
                <div className="fw-normal h4">
                  <i className="fas fa-road me-2 text-secondary" /> {new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(vehicle.mileage)} km
                </div>
                <div className="fw-normal h4">
                  <i className="fas fa-gas-pump me-2 text-secondary" /> {vehicle.specifics.find(specific => specific.key === 'ad:fuel')?.value}
                </div>
                <div className="fw-normal h4">
                  <i className="fas fa-tachometer-alt me-2 text-secondary" /> {vehicle.power} kW ({Math.round(vehicle.power*1.36)} PS)
                </div>
                <div className="fw-normal h4">
                  <i className="fas fa-cog me-2 text-secondary" /> {vehicle.specifics.find(specific => specific.key === 'ad:gearbox')?.value}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 lorbeerblatt">
            <img src={activeImage && activeImage.replace('mo-200.jpg', 'mo-640.jpg')} className="mt-lg-5 ms-lg-3 rounded img-fluid" alt="" style={{width:450}} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 order-last order-md-first">
            
            {showDetail ?
              <>
                <Link to={`/verkauf/${vehicle.id}/${generatePermalink(vehicle.title)}`} type="button" className="d-lg-none mt-3 btn btn-secondary border-white">
                  <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Fahrzeugdetails</span>
                </Link>
                <div className="d-none d-lg-block">
                  <Link to={`/verkauf/${vehicle.id}/${generatePermalink(vehicle.title)}`} type="button" className="text-start mt-3 btn btn-secondary btn-lg border-white">
                    <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Fahrzeugdetails</span>
                  </Link>
                </div>
              </>
            : null}
            
            {showWhatsApp ?
              <>
                <a href="https://api.whatsapp.com/send/?phone=+4915206348058&text&app_absent=0" type="button" className="d-lg-none mt-3 btn border-white text-white" style={{backgroundColor: '#1ebea5'}}>
                  <i className="fab fa-whatsapp me-1" /> <span className="fw-bold">WhatsAppen</span>
                </a>
                <div className="d-none d-lg-block">
                  <a href="https://api.whatsapp.com/send/?phone=+4915206348058&text&app_absent=0" type="button" className="mt-3 btn btn-lg border-white text-white" style={{backgroundColor: '#1ebea5'}}>
                    <i className="fab fa-whatsapp me-1" /> <span className="fw-bold">WhatsAppen</span>
                  </a>
                </div>
              </>
            : null}
            
            {showTestDrive ?
              <>
                <button type="button" className="d-lg-none mt-2 btn btn-info border-white" data-bs-toggle="modal" data-bs-target="#probefahrt-vereinbaren-modal">
                  <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Probefahrt vereinbaren</span>
                </button>
                <div className="d-none d-lg-block">
                  <button type="button" className="mt-3 btn btn-link btn-lg border" data-bs-toggle="modal" data-bs-target="#probefahrt-vereinbaren-modal">
                    <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Probefahrt vereinbaren</span>
                  </button>
                </div>
              </>
            : null}

          </div>
          <div className="col-12 col-md-6 order-first order-md-last">
            <div className="row">
              <div className="col-1 text-end align-self-center">
                {sliderIndex === 0 ? null : <i className="fas fa-arrow-left fa-1x" onClick={() => setSliderIndex(sliderIndex !== 0 ? sliderIndex-1 : sliderIndex)} />}
              </div>
              <div className="col-10 text-center py-3 d-lg-none">
                {imageGalerie && imageGalerie[sliderIndex].map((image, index) => <img key={index} src={image} className="mx-1 mx-lg-2 img-fluid" alt={vehicle.title} style={{width:65}} onClick={() => setActiveImage(image)} />)}
              </div>
              <div className="col-md-10 col-xl-7 text-center py-3 d-none d-lg-block">
                {imageGalerie && imageGalerie[sliderIndex].map((image, index) => <img key={index} src={image} className="mx-1 mx-lg-2 img-fluid" alt={vehicle.title} style={{width:100}} onClick={() => setActiveImage(image)} />)}
              </div>
              <div className="col-1 align-self-center">
                {imageGalerie && sliderIndex === (imageGalerie.length-1) ? null : <i className="fas fa-arrow-right text-secondary fa-1x" onClick={() => setSliderIndex(sliderIndex < (imageGalerie.length-1) ? sliderIndex+1 : sliderIndex)} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </> : null;
}

export default VehicleHeader;
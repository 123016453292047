const StatisticBar = ({theme}) => {
  const textColor = (theme === 'secondary' || theme === 'primary') ? 'text-white' : 'text-dark'

  return <>
    <div className={`container-fluid bg-${theme} py-5`}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className={`h2 ${textColor}`}>Fairness &amp; Service für unsere Kunden</div>
            <p className={textColor}>Wir sind stolz darauf Gebrauchtwagenhändler mit Leidenschaft &amp; Expertise zu sein.</p>
            <a className="btn btn-light text-center mt-4 p-2 px-4" href="https://www.google.com/search?q=ps+kraftwagen+gmbh&rlz=1C5CHFA_enDE849DE849&sxsrf=ALeKk03hWLf3xMhYZ9CBXF-cJDUD4HhptA%3A1611050870002&ei=da8GYKXXPI3zkgXv65eoDQ&oq=pskraftwagen+gm&gs_lcp=CgZwc3ktYWIQAxgAMgYIABAWEB46BAgjECc6AggmULZDWNpGYMlSaABwAHgAgAFciAG5ApIBATSYAQCgAQGqAQdnd3Mtd2l6wAEB&sclient=psy-ab#lrd=0x4799a3c200d37da7:0x951410fd6e1372b2,1,,">
              <i className="fas fa-star text-warning me-1" /> <span className="fw-bold text-dark">Bewertungen auf Google ansehen</span>
            </a>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-md-3 d-md-none text-center">
            <div className={`h2 ${textColor} fw-bold`}>200+</div>
            <p className={textColor}>5 <i className={`fas fa-star ${textColor}`} /> Bewertungen auf Google Business</p>
          </div>
          <div className="col-12 col-md-3 d-none d-md-block border-end text-center">
            <div className={`h2 fw-bold ${textColor}`}>200+</div>
            <p className={textColor}>5 <i className={`fas fa-star ${textColor}`} /> Bewertungen auf Google Business</p>
          </div>
          <div className="col-12 col-md-3 d-md-none text-center">
            <div className={`h2 fw-bold ${textColor}`}>&gt;700</div>
            <p className={textColor}>Verkaufte Gebrauchtwagen</p>
          </div>
          <div className="col-12 col-md-3 d-none d-md-block border-end text-center">
            <div className={`h2 fw-bold ${textColor}`}>&gt;700</div>
            <p className={textColor}>Verkaufte Gebrauchtwagen</p>
          </div>
          <div className="col-12 col-md-3 d-md-none text-center">
            <div className={`h2 fw-bold ${textColor}`}>100%</div>
            <p className={textColor}>Kundenzufriedenheit im Ankauf &amp; Verkauf</p>
          </div>
          <div className="col-12 col-md-3 d-none d-md-block border-end text-center">
            <div className={`h2 fw-bold ${textColor}`}>100%</div>
            <p className={textColor}>Kundenzufriedenheit im Ankauf &amp; Verkauf</p>
          </div>
          <div className="col-12 col-md-3 d-md-none text-center">
            <div className={`h2 fw-bold ${textColor}`}>15 Jahre</div>
            <p className={textColor}>Erfahrung im Gebrauchtwagenhandel</p>
          </div>
          <div className="col-12 col-md-3 d-none d-md-block text-center">
            <div className={`h2 fw-bold ${textColor}`}>15 Jahre</div>
            <p className={textColor}>Erfahrung im Gebrauchtwagenhandel</p>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default StatisticBar;
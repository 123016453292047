import {useEffect} from 'react';
import {Link} from 'react-router-dom';

import ContactBar from '../components/ContactBar';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';
import Testimonial from '../components/Testimonial';

import begutachtungFahrzeug from '../assets/images/begutachtung-verkauf-fahrzeug.jpg';
import portraitSebastianMerkel from '../assets/images/portrait-sebastian-merkel.jpg';
import wirSindPSKraftwagenImage from '../assets/images/sebastian-merkel-am-arbeitsplatz.jpg';
// import googleMapsStaticImage from '../assets/images/google-maps-static.png';

const Home = () => {
  
  useEffect(() => {
    const pageTitle = 'PS Kraftwagen GmbH | Autoankauf Uhingen';

    document.title = pageTitle;

    window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)]);
    window._paq.push(['setDocumentTitle', pageTitle]);
    window._paq.push(['trackPageView']);
  });

  return <>
    <Header />
    
    <HeroSection />

    <div className="container-fluid mb-5 py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-2 text-center text-lg-start">
            <img src={portraitSebastianMerkel} alt="Portrait Sebastian Merkel" className="rounded-circle shadow" style={{width:220}} />
          </div>
          <div className="col-12 col-lg-8">
            <div className="h2 text-secondary fw-bold text-center text-lg-start mt-3 mt-lg-5 ms-lg-4">Die Mission von PS Kraftwagen</div>
            <p className="h4 text-secondary fw-light mt-3 ms-lg-4">
              <i className="fas fa-angle-double-right text-primary me-1" />Wir möchten unseren Kunden zeigen, dass es noch vertrauenswürdige &amp; faire Gebrauchtwagenhändler gibt
            </p>
            <div className="h5 text-secondary fw-light ms-4">- Sebastian Merkel</div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid my-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="h1 text-secondary fw-bold">Alle Vorteile bei PS Kraftwagen</div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-lg-6 order-last order-lg-first">
            <div className="row align-items-center bg-info bg-gradient rounded shadow mt-3 mt-lg-1">
              <div className="col-3 col-lg-2 text-center border-end py-4"><i className="fas fa-thumbs-up fa-3x fa-fw text-white" /></div>
              <div className="col-9 col-lg-10 p-3 text-secondary">
                <span className="h4 fw-bold d-block">100% Kundenzufriedenheit</span>
                <p>Wir leben Gebrauchtwagenhandel und lieben zufriedene Kunden die uns für unseren besonderen Service weiterempfehlen</p>
              </div>
            </div>
            <div className="row align-items-center bg-info bg-gradient rounded shadow mt-3">
              <div className="col-3 col-lg-2 text-center border-end py-4"><i className="fas fa-handshake fa-3x fa-fw text-white" /></div>
              <div className="col-9 col-lg-10 p-3 text-secondary">
                <span className="h4 fw-bold d-block">Beratung auf Augenhöhe</span>
                <p>Wir möchten jedem Menschen auf Augenhöhe begegnen und eine individuelle Beratung bieten die zum Kunde &amp; seinem Bedürfnis passt</p>
              </div>
            </div>
            <div className="row align-items-center bg-info bg-gradient rounded shadow mt-3">
              <div className="col-3 col-lg-2 text-center border-end py-4"><i className="fas fa-euro-sign fa-3x fa-fw text-white" /></div>
              <div className="col-9 col-lg-10 p-3 text-secondary">
                <span className="h4 fw-bold d-block">Faire Preise im Ankauf &amp; Verkauf</span>
                <p>Es ist unser Anspruch bei jedem An- und Verkauf eine Win-Win Situation durch faire Preisgestaltung zu schaffen</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-first order-lg-last">
            <img src={begutachtungFahrzeug} alt="Begutachtung Fahrzeug Innenraum" className="img-fluid rounded shadow" />
          </div>
        </div>
      </div>
    </div>

    <Testimonial />
    
    <div className="container py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 order-last order-lg-first">
            <div className="mt-4 text-secondary fw-bolder text-uppercase h1">PS Kraftwagen Team</div>
            <p className="bg-white mt-3 mb-5 p-0 p-lg-2 fw-bold text-secondary">
              Gemeinsam geht mehr – gemäß diesem Motto haben wir uns Anfang 2020 zusammengeschlossen um das Image des Gebrauchtwagenhandels neu zu definieren:
              <span className="d-block mt-3"><i className="fas fa-chevron-circle-right me-1" /> Transparent</span>
              <span className="d-block mt-1 mt-lg-2"><i className="fas fa-chevron-circle-right me-1" /> Fair</span>
              <span className="d-block mt-1 mt-lg-2"><i className="fas fa-chevron-circle-right me-1" /> Top Service in allen Bereichen</span>
            </p>
            <div className="d-none d-lg-block">
              <Link to="/team" type="button" className="btn btn-secondary btn-lg px-5 border-white">
                <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Team kennenlernen</span>
              </Link>
            </div>
            <div className="d-lg-none">
              <Link to="/team" type="button" className="btn btn-secondary px-4 border-white">
                <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Team kennenlernen</span>
              </Link>
            </div>
          </div>
          <div className="col-12 col-lg-6 order-first order-lg-last">
            <img src={wirSindPSKraftwagenImage} alt="Wir sind PS Kraftwagen" className="img-fluid rounded shadow" />
          </div>
        </div>
      </div>
    </div>
    
    <ContactBar variant="dark" />

    {/* <a style={{height:400, background: 'url(/google-maps-static.png) center center no-repeat', backgroundSize: 'cover'}} className="d-block" href="https://www.google.de/maps/place/PS+Kraftwagen+GmbH/@48.6957341,9.6587473,17z/data=!4m12!1m6!3m5!1s0x4799a3c200d37da7:0x951410fd6e1372b2!2sPS+Kraftwagen+GmbH!8m2!3d48.6956883!4d9.660918!3m4!1s0x4799a3c200d37da7:0x951410fd6e1372b2!8m2!3d48.6956883!4d9.660918" target="_blank" rel="noreferrer"> */}
      {/* <img src={googleMapsStaticImage} alt="Google Maps statisches Bild" className="cursor-pointer" /> */}
    {/* </a> */}

    <div className="container-fluid py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-9 text-center">
            <p className="text-primary text-center text-lg-justify p-lg-4">
              PS Kraftwagen GmbH ist Ihr Gebrauchtwagenhändler für Autoankauf &amp; Verkauf in 73066 Uhingen und Umgebung. Wir kaufen und handeln mit ausgewählten 
              Gebrauchtwagen aller namhaften Hersteller. Es ist unser Anspruch allen Kunden ein faires Angebot &amp; eine stressfreie, schnelle Abwicklung zu bieten. 
              Seit unserer Gründung als „PS CarSolutions“ Anfang 2020 haben wir mit über 1000 Fahrzeugen gehandelt und erfreuen uns einer 100% Kundenzufriedenheit. 
              Überzeugen Sie sich von unseren attraktiven Gebrauchtwagen oder lernen Sie unser Team kennen. PS Kraftwagen freut sich auf Sie!
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </>
}

export default Home;

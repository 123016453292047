import React, {useEffect, useState} from 'react';


const Testimonial = () => {
  const [reviews, setReviews] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);

  useEffect(() => {

    async function fetchData() {
      const googleReviewsRequest = await fetch('/reviews.json');
      const googleReviewsResponse = googleReviewsRequest.status === 200 ? await googleReviewsRequest.text() : undefined;
      const googleReviews = googleReviewsResponse ? JSON.parse(googleReviewsResponse) : undefined;
      const reviewMapper = [];
      let mapperCounter = 0;
      let loopCounter = 1;

      googleReviews.forEach(review => {
        if (!reviewMapper[mapperCounter]) {
          reviewMapper[mapperCounter] = [];
        }

        if (loopCounter % 3 === 0) {
          reviewMapper[mapperCounter].push(review);
          mapperCounter++;
        } else {
          reviewMapper[mapperCounter].push(review);
        }

        loopCounter++;
      });

      setReviews(reviewMapper);
    }

    fetchData();

  }, []);

  return reviews && reviews[sliderIndex] ? <>
    <div className="container-fluid bg-secondary bg-gradient py-5">
      <h3 className="mt-3 text-white fw-bolder text-uppercase text-center">Kundenstimmen</h3>
      <div className="separator bg-primary mx-auto" />
      <div className="row justify-content-center">
        <div className="d-none d-md-block col-1 align-self-center">
          {sliderIndex === 0 ? null : <i className="fas fa-arrow-left text-white fa-2x" onClick={() => setSliderIndex(sliderIndex !== 0 ? sliderIndex-1 : sliderIndex)} />}
        </div>
        {reviews[sliderIndex].map((review, id) => 
          <div className="col-12 col-md-3 mt-3" key={id}>
            <div className="shadow text-center p-3 rounded bg-white" style={{minHeight:330}}>
              <img src={review.imagePath} alt={review.author} className="rounded-circle" style={{width:40}} />
              <div className="">
                <span className="d-block text-secondary fw-bold mt-3">{review.author}</span>
                <span className="d-block text-primary h6">Käufer/in 
                  {review.stars ? <>
                    <br/>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    <i className="fas fa-star text-warning"></i>
                    {/* <span className="text-secondary ms-2">{review.createdAt}</span> */}
                  </>
                : null}
                </span>
                  <span className="d-block font-italic mt-3 h6">"{review.reviewText}"</span>
              </div>
            </div>
          </div>
        )}
        <div className="d-none d-md-block col-1 align-self-center">
          {sliderIndex === (reviews.length-1) ? null : <i className="fas fa-arrow-right text-white fa-2x" onClick={() => setSliderIndex(sliderIndex < (reviews.length-1) ? sliderIndex+1 : sliderIndex)} />}
        </div>
      </div>
    </div>
  </> : null;
}

export default Testimonial;

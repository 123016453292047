import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const HeroSection = () => {
  const [fade, setFade] = useState(false);
  const [active, setActive] = useState('ankauf');

  useEffect(() => {

    if (active === 'ankauf') {
      document.querySelector('section#hero #ankauf').classList.remove('inactive');
      document.querySelector('section#hero #verkauf').classList.add('inactive');
    }

    if (active === 'verkauf') {
      document.querySelector('section#hero #verkauf').classList.remove('inactive')
      document.querySelector('section#hero #ankauf').classList.add('inactive');
    }
    
    setTimeout(() => {
      setFade(true);
    }, 700);
  });

  const getTeaserText = () => active === 'ankauf' ? <>
    <div className={fade ? "fade show" : "fade"}>
      <h1 className="text-uppercase mt-5 fw-bold">Wir kaufen Ihren Kraftwagen</h1>
      <p className="text-uppercase mt-5 h4 fw-lighter"><i className="fas fa-chevron-circle-right me-2" />Faires Angebot</p>
      <p className="text-uppercase h4 fw-lighter"><i className="fas fa-chevron-circle-right me-2" />Unkomplizierte Abwicklung</p>
      <p className="text-uppercase h4 fw-lighter"><i className="fas fa-chevron-circle-right me-2" />Top Service</p>
    </div>
  </> :
  <>
    <div className={fade ? "fade show" : "fade"}>
      <h1 className="text-uppercase mt-5 fw-bold">Aktuelle Kraftwagen Angebote</h1>
      <p className="text-uppercase mt-5 h4 fw-lighter"><i className="fas fa-chevron-circle-right me-2" />Top Modelle</p>
      <p className="text-uppercase h4 fw-lighter"><i className="fas fa-chevron-circle-right me-2" />Faire Preise</p>
      <p className="text-uppercase h4 fw-lighter"><i className="fas fa-chevron-circle-right me-2" />Beste Auswahl</p>
    </div>
  </>

  return <>
    <section className="container-fluid bg-secondary py-5 d-lg-none">
      <div className="container">
        <div className="row">
          <div className="col-12 text-white">
            <h1 className="text-uppercase fw-bold">Wir kaufen Ihren Kraftwagen</h1>
            <p className="text-uppercase mt-4 h5 fw-lighter"><i className="fas fa-chevron-circle-right me-2" />Faires Angebot</p>
            <p className="text-uppercase h5 fw-lighter"><i className="fas fa-chevron-circle-right me-2" />Unkomplizierte Abwicklung</p>
            <p className="text-uppercase h5 fw-lighter"><i className="fas fa-chevron-circle-right me-2" />Top Service</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <Link to="/ankauf" type="button" className="text-secondary btn btn-light mt-5 px-4"><i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold text-secondary">Ankauf starten</span></Link>
          </div>
        </div>
      </div>
    </section>

    <section id="hero" className={active === 'ankauf' ? "container-fluid bg-secondary d-none d-lg-block" : "container-fluid bg-primary d-none d-lg-block"}>
      <div className="row h-100">
        <div className="col-4 fw-bold text-white p-5">
          <div className="row h-100 justify-content-end">
            <div className="col-10">
              {getTeaserText()}
            </div>
          </div>
        </div>
        <div id="ankauf" className="col-4 text-center py-5" onMouseOver={() => setActive('ankauf')}>
          <Link to="/ankauf" type="button" className="btn btn-secondary btn-lg px-5 border-white" style={{marginTop: 530}}><i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Ankauf</span></Link>
        </div>
        <div id="verkauf" className="col-4 text-center py-5 inactive" onMouseOver={() => setActive('verkauf')}>
          <Link to="/verkauf" type="button" className="btn btn-secondary btn-lg px-5 border-white" style={{marginTop: 530}}><i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Verkauf</span></Link>
        </div>
      </div>
    </section>
  </>
}

export default HeroSection;

import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom'

import Footer from '../components/Footer';
import Header from '../components/Header';
import Stock from '../components/Stock';
import StoreContext from '../storeContext';
import VehicleHeader from '../components/VehicleHeader';

const VerkaufDetail = () => {
  const {state} = useContext(StoreContext);
  const [vehicle, setVehicle] = useState(null);
  const {id} = useParams()

  useEffect(() => {
    document.body.scrollIntoView();

    if (state.vehicles.length > 0) {
      const foundVehicle = state.vehicles.find(vehicle => vehicle.id === id);

      setVehicle(foundVehicle);

      const pageTitle = `PS Kraftwagen GmbH | Verkauf | ${foundVehicle.title}`;
  
      document.title = pageTitle;
  
      window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)]);
      window._paq.push(['setDocumentTitle', pageTitle]);
      window._paq.push(['trackPageView']);
    }
  }, [state, id]);

  return vehicle ? <>
    <Header />

    <div className="modal fade" id="probefahrt-vereinbaren-modal" tabIndex="-1" aria-labelledby="probefahrt-vereinbaren-modal-label" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="probefahrt-vereinbaren-modal-label">Probefahrt vereinbaren</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            Für eine Probefahrt nehmen Sie einfach Kontakt mit uns auf.
            <a href="tel:+4971618085101" className="d-block text-center mt-4 border bg-light p-3">
              <i className="fas fa-phone fa-1x pe-2" />
              <span className="h4">07161 808 51 01</span>
            </a>
            <a href="https://api.whatsapp.com/send/?phone=+4915206348058&text&app_absent=0" className="d-block text-center mt-2 border bg-light p-3">
              <i className="fab fa-whatsapp fa-1x pe-2" />
              <span className="h4">01520 / 6348058</span>
            </a>
            <a href="mailto:info@pskraftwagen.de" className="d-block text-center mt-2 border bg-light p-3">
              <i className="fas fa-envelope fa-1x pe-2" />
              <span className="h4">info@pskraftwagen.de</span>
            </a>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>

    <VehicleHeader vehicle={vehicle} showDetail={false} showWhatsApp={true} showTestDrive={true} />

    <div className="container-fluid mb-3 d-md-none">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mt-4 text-uppercase text-secondary fw-bolder">Technische Daten</h2>
            {vehicle.specifics.map(specific =>
              <div key={specific.key} className="mt-1 p"><i className="fas fa-chevron-circle-right me-1 text-secondary" /> <span className="fw-bold me-2">{specific.title}</span> <span className="">{specific.value}</span></div>
            )}
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid mb-3 d-md-none">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mt-4 text-uppercase text-secondary fw-bolder">Ausstattung</h2>
            <div className="row">
              {vehicle.features.map(feature =>
                <div key={feature} className="col-12 p"><i className="fas fa-check me-1 text-secondary" /> <span className="fw-normal me-2">{feature}</span></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid mt-md-5 mb-3 d-none d-md-block">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h2 className="mt-4 text-uppercase text-secondary fw-bolder">Technische Daten</h2>
          </div>
          <div className="col-8">
            <h2 className="mt-4 ms-4 text-uppercase text-secondary fw-bolder">Ausstattung</h2>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid mb-5 d-none d-md-block">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <div className="">
              {vehicle.specifics.map(specific =>
                <div key={specific.key} className="mt-1"><i className="fas fa-chevron-circle-right me-1 text-secondary" /> <span className="fw-bold me-2">{specific.title}</span> <span className="">{specific.value}</span></div>
              )}
            </div>
          </div>
          <div className="col-8 border-start">
            <div className="px-4">
              <div className="row">
                {vehicle.features.map(feature =>
                  <div key={feature} className="col-6"><i className="fas fa-check me-1 text-secondary" /> <span className="fw-normal me-2">{feature}</span></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Stock />

    <Footer />
  </> : null;
}

export default VerkaufDetail;
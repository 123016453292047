import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import Footer from '../components/Footer';
import Header from '../components/Header';

import verkaufGebrauchtwagen from '../assets/images/uebergabe-autoschluessel.jpg';
import winWinSituationen from '../assets/images/wir-schaffen-win-win-situationen.jpg';
import kontakt from '../assets/images/wir-schaffen-win-win-situationen.jpg';

const Ankauf = () => {
  const navigate = useNavigate()
  const [formShowSection, setFormShowSection] = useState('vehicle')
  const [formManufacturer, setFormManufacturer] = useState('')
  const [formModel, setFormModel] = useState('')
  const [formVariant, setFormVariant] = useState('')
  const [formName, setFormName] = useState('')
  const [formEmail, setFormEmail] = useState('')
  const [formPhone, setFormPhone] = useState('')

  useEffect(() => {
    const pageTitle = 'PS Kraftwagen GmbH | Ankauf';

    document.title = pageTitle;

    window._paq.push(['setCustomUrl', '/' + window.location.hash.slice(1)]);
    window._paq.push(['setDocumentTitle', pageTitle]);
    window._paq.push(['trackPageView']);
  });

  const handleSubmit = async (event) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('manufacturer', formManufacturer)
    formData.append('model', formModel)
    formData.append('variant', formVariant)
    formData.append('name', formName)
    formData.append('email', formEmail)
    formData.append('phone', formPhone)

    const request = await fetch('https://www.pskraftwagen.de/php/sendmail.php', {
      method: 'POST',
      body: formData
    });

    const response = await request.json();

    if (response.success) {
      navigate('/ankauf/bewertung/danke')

      setFormManufacturer('')
      setFormModel('')
      setFormVariant('')
      setFormName('')
      setFormEmail('')
      setFormPhone('')
      setFormShowSection('vehicle')
    } else {
      // formShowSection('senterror');
    }

  }

  return <>
    <Header />
    
    <form className="container-fluid py-5" onSubmit={(e) => handleSubmit(e)}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 order-last order-lg-first">
            
            {formShowSection !== 'contact' ? <h1 className="mt-4 mt-lg-5 text-uppercase text-secondary fw-bolder">Wir kaufen Ihren Gebrauchtwagen zum Höchstpreis</h1> : null}
            {formShowSection === 'contact' ? <h1 className="mt-4 mt-lg-5 text-uppercase text-secondary fw-bolder">Ihre Kontaktdaten</h1> : null}

            {formShowSection === 'vehicle' ? <>
            <div className="row mt-4">
              <div className="col-12">
                <div className="small">Marke</div>
                <input className="form-control" placeholder="z.B. VW" value={formManufacturer} onChange={(e) => setFormManufacturer(e.target.value)} required />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <div className="small">Modell</div>
                <input className="form-control" placeholder="z.B. Passat" value={formModel} onChange={(e) => setFormModel(e.target.value)} required />
              </div>
              <div className="col-6">
                <div className="small">Motor</div>
                <input className="form-control" placeholder="z.B. 2.0 TDI" value={formVariant} onChange={(e) => setFormVariant(e.target.value)} required />
              </div>
            </div>
            <div className="row mt-4 justify-content-end">
              <div className="col-6">
                {formManufacturer && formModel && formVariant ?
                  <button className="form-control btn btn-secondary" onClick={() => setFormShowSection('contact')}>Weiter</button> :
                  <button type="submit" className="form-control btn btn-secondary">Weiter</button>
                }
              </div>
            </div>
            </> : null}

            {formShowSection === 'contact' ? <>
            <div className="row mt-4">
              <div className="col-12">
                <input className="form-control" placeholder="Name" value={formName} onChange={(e) => setFormName(e.target.value)} required />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <input type="email" className="form-control" placeholder="E-Mail Adresse" value={formEmail} onChange={(e) => setFormEmail(e.target.value)} required />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <input className="form-control" placeholder="Mobiltelefon" value={formPhone} onChange={(e) => setFormPhone(e.target.value)} required />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-1 text-end">
                <input type="checkbox" className="form-check-input" required />
              </div>
              <div className="col-11">
                <span className="text-secondary small">Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer</span> <a href="/datenschutz" target="_blank" className="small">Datenschutzerklärung</a>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-11">
                <div className="small text-secondary fw-bold">Information</div>
                <span className="text-secondary small">Mir ist bewusst, dass das finale Verkaufsangebot unter Berücksichtigung von Schäden und Fahrzeugzustand vor Ort erstellt wird.</span><br/>
                <span className="text-secondary small">Die PS Kraftwagen GmbH darf mich nach der kostenlosen Schätzung meines KfZ per E-Mail oder Telefon kontaktieren um das Angebot zu besprechen.</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <button type="submit" className="form-control btn btn-secondary">Absenden</button>
              </div>
            </div>
            </> : null}
          </div>
          <div className="col-12 col-lg-7 order-first order-lg-last">
            {formShowSection !== 'contact' ? <img src={verkaufGebrauchtwagen} alt="Verkauf eines Gebrauchtwagens" className="img-fluid rounded shadow" /> : null}
            {formShowSection === 'contact' ? <img src={kontakt} alt="Verkauf eines Gebrauchtwagens" className="img-fluid rounded shadow" /> : null}
          </div>
        </div>
      </div>
    </form>

    <div className="container-fluid py-5 bg-secondary bg-gradient">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 text-center">
            <div className="text-white">
              <i className="fas fa-thumbs-up fa-6x fa-fw" />
              <div className="h4 fw-bold mt-3">Top Service</div>
              <p>Lassen Sie sich von unserem Service im Ankauf überzeugen</p>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center">
            <div className="text-white">
              <i className="fas fa-check-circle fa-6x fa-fw" />
              <div className="h4 fw-bold mt-3">Schnelle Abwicklung</div>
              <p>Unkomplizierte &amp; schnelle Abwicklung mit direkter Bezahlung</p>
            </div>
          </div>
          <div className="col-12 col-md-4 text-center">
            <div className="text-white">
              <i className="fas fa-euro-sign fa-6x fa-fw" />
              <div className="h4 fw-bold mt-3">Faires Angebot</div>
              <p>Wir bieten Ihnen einen fairen Preis für Ihren Gebrauchtwagen</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <img src={winWinSituationen} alt="Verkauf eines Gebrauchtwagens" className="img-fluid rounded shadow" />
          </div>
          <div className="col-12 col-lg-6 ps-lg-5">
            <div className="mt-4 h1 text-uppercase text-secondary fw-bolder">Wir schaffen Win-Win Situationen</div>
            <p>Sebastian Merkel</p>
            <div className="d-none d-lg-block">
              <Link to="/team" type="button" className="mt-4 btn btn-secondary btn-lg px-5 border-white">
                <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Team kennenlernen</span>
              </Link>
            </div>
            <Link to="/team" type="button" className="d-lg-none mt-4 btn btn-secondary px-5 border-white">
              <i className="fas fa-chevron-circle-right me-1" /> <span className="fw-bold">Team kennenlernen</span>
            </Link>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </>
}

export default Ankauf;
